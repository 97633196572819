@import "variables";

.utility-wrap {
  width: 100%;

  @include max-width($md) {
    position: fixed;
    bottom: 0;
    z-index: 1;
    left: 0;
    pointer-events: none;
  }

  @include min-width($md) {
    top: var(--top);
    height: var(--height);
    display: block;
    position: absolute;
    width: 100%;
    padding-right: 0.5rem;
    left: calc(var(--container-width));
    width: calc((100% - var(--container-width)));
    padding-left: calc(var(--gutter) - #{rem(12)});
    visibility: var(--visibility);
  }

  @include min-width($lg) {
    left: calc(50% + var(--container-width) / 2);
    width: calc((100% - var(--container-width)) / 2);
  }
}

.fade-height-enter {
  opacity: 0;
  height: 0;
  transform: translateY(-2rem);
  pointer-events: none;
}
.fade-height-enter-active {
  opacity: 1;
  height: var(--auto-height);
  transform: translateY(0);
  pointer-events: none;
  transition: transform 0.4s var(--ease-out), opacity 1s var(--ease-out), height 0.4s var(--ease-out);
}
.fade-height-exit {
  opacity: 1;
  height: var(--auto-height);
  transform: translateY(0);
  pointer-events: none;
}
.fade-height-exit-active {
  opacity: 0;
  height: 0;
  pointer-events: none;
  transform: translateY(-2rem);
  transition: transform 0.4s var(--ease-out), opacity 0.3s var(--ease-out), height 0.4s var(--ease-out);
}

.Utility {
  position: sticky;
  top: var(--header-height);
  max-height: calc(100vh - var(--header-height));
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--bg);
  margin-left: auto;
  display: flex;
  flex-direction: column;
  --family: var(--ui-label);
  transition: transform 0.4s var(--ease-out);
  padding-inline-end: rem(12);

  @include max-width($md) {
    padding-block: rem(8) 0;
    box-shadow: var(--shadow);
    transform: translateY(100%);
    pointer-events: none;
    max-height: MIN(60vh, calc(100vh - var(--header-height) - 4rem));
    .Article:not(.isFooterVisible) &.isScrollingUp,
    &:has(:focus-visible) {
      pointer-events: auto;
      transform: translateY(0%);
    }
  }
  &.expanded {
    --gutter-count: 1;
    @include min-width($md) {
      position: fixed;
      width: calc((MIN(100vw, var(--site-max-width)) - var(--container-width)) / var(--gutter-count) - calc(var(--gutter) - 0.75rem) - 0.5rem);
    }
    @include min-width($lg) {
      --gutter-count: 2;
    }
  }

  .spacer {
    display: block;
    height: var(--input-height);
    flex: 0 0 auto;
    @include max-width($md) {
      &.half {
        height: calc(var(--input-height) - rem(15));
      }
      &.third {
        height: calc(var(--input-height) - rem(20));
      }
    }
  }
  
  .mobile-cart-button {
    @include min-width($md) {
      display: none;
    }
  }
  .desktop-cart-button {
    @include max-width($md) {
      display: none;
    }
  }

  .Button {
    margin-inline: auto;
  }
  --input-height: #{rem(28)};
  @media (pointer: coarse) {
    --input-height: #{rem(35)};
  }
  .utility-toggle-container {
    position: sticky;
    bottom: 0;
    padding-block-end: env(safe-area-inset-bottom, 0);
    background: var(--bg);
    padding-inline: 0.6rem;
    display: flex;
    justify-content: center;
  }
  .utility-toggle {
    padding-block: 0;
    font-size: rem(15);
    display: flex;
    justify-content: space-between;
    gap: rem(12);
    line-height: var(--input-height);
    font-weight: 500;
    .arrow {
      transform: rotate(90deg);
    }
    &[aria-expanded="true"] {
      .arrow {
        transform: rotate(-90deg);
      }
    }
  }
}
.controls-wrapper {
  &.lightly-padded {
    padding-inline: rem(10);
  }
  &.centered {
    display: flex;
    justify-content: center;
  }
}

.FamilySelector {
  font-size: rem(16);
  .select {
    position: relative;
    width: 100%;
  }
  .arrow {
    position: absolute;
    right: 0;
    height: var(--input-height);
    width: calc(0.75 * var(--input-height));
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    svg {
      rotate: -90deg;
    }
  }
  .label {
    font-size: rem(15);
    --family: var(--ui-label);
    font-weight: 500;
    padding: 0 rem(12);
    line-height: var(--input-height);
  }

  &__input-container {
    display: flex;

    &:focus-within {
      background: var(--bg-1);
    }
  }

  &__input-scroll {
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    flex: 1 1 auto;

    // Hide scrollbar for IE, Edge and Firefox
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__options {
    display: flex;
    --family: var(--ui-value);

    label {
      display: block;
      width: 100%;
      flex: 0 0 auto;
      padding: 0 rem(12);
      scroll-snap-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: var(--input-height);
    }

    input {
      opacity: 0;
      position: absolute;
    }
  }

  select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding-block: 0;
    height: var(--input-height);
    padding-inline: rem(12);
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    color: inherit;
    line-height: inherit;
  }

  .buttons {
    display: flex;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--input-height);
      width: calc(3 / 4 * var(--input-height));
      padding: 0;
    }
  }
}

.Toggle {
  padding-inline: rem(12);
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  color: var(--fg);
  gap: 1rem;
  font-size: rem(16);
  font-style: normal;
  font-weight: 400;
  line-height: rem(17);
  text-align: center;
  --family: var(--ui-label);

  .viz {
    width: rem(40);
    height: rem(16);
    background: var(--mid-light-gray);
    border-radius: 20px;
    position: relative;
    transition: background 0.4s ease-in-out;

    &::before {
      content: "";
      display: block;
      position: absolute;
      height: calc(100% - 2px);
      aspect-ratio: 1 / 1;
      left: 1px;
      top: 1px;
      background: var(--bg);
      border-radius: 20px;
      transition: left 0.2s ease-in-out;
    }
  }

  &.checked {
    .viz {
      background: var(--blue);

      &::before {
        left: calc(100% - 14px - 1px);
      }
    }
  }

  input {
    // accessible but invisible
    position: absolute;
    left: -9999px;
    top: -9999px;
    width: 0;
    height: 0;
  }
}

.NumericInput {
  --family: var(--ui-label);
  display: flex;
  position: relative;
  flex: 1;
  padding: 0 0 0 rem(12);
  border-bottom-width: rem(6);
  font-size: rem(16);
  height: var(--input-height);

  label {
    display: flex;
    align-items: center;
    margin-right: auto;
    cursor: ns-resize;
    line-height: 1;
    .short-label {
      display: none;
    }
    @include min-width($md) {
      @include max-width($md + 100px) {
        .short-label {
          display: inline;
        }
        .full-label {
          display: none;
        }
      }
    }
    @include min-width($lg) {
      @include max-width($lg + 100px) {
        .short-label {
          display: inline;
        }
        .full-label {
          display: none;
        }
      }
    }
  }

  .text-items {
    position: relative;
  }

  input,
  output {
    display: block;
    cursor: inherit;
    text-align: end;
  }

  input {
    width: 7.3ch;
    flex: 0 0 auto;
    display: block;
    border: none;
    padding: 0;

    padding: 0 0.5rem;
    top: -0.3rem;
    left: -0.5rem;
    bottom: -0.3rem;
    right: -0.5rem;
    font-size: rem(16);

    appearance: none;
    background: none;
    color: inherit;

    &:focus {
      outline: 0;
    }

    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button {
    display: block;
    line-height: 1;
    padding-bottom: 0.2rem;
    padding-inline: 0;
    height: var(--input-height);
    width: calc(3 / 4 * var(--input-height));
  }
}
.Button {
  border: 0;
  text-decoration: none;
  background: none;
  border-radius: 3em;
  cursor: pointer;
  display: inline-flex;
  box-shadow: 0 0 0 1px currentColor;
  font-family: var(--ui-label);
  font-style: normal;
  font-weight: normal;
  font-size: rem(16);
  line-height: calc(var(--input-height) - rem(1));
  padding: rem(1) calc(var(--input-height) / 3) 0;
  gap: rem(8);
  align-items: center;
  transition: color 0.2s var(--ease-out);
  &.loading {
    color: var(--mid-gray);
    pointer-events: none;
  }
}

.LinkedFamilyName {
  &__external {
    width: 1.4rem;
    height: 0.8rem;
    padding: 0 0.3rem;
    display: inline-block;
    position: relative;
    top: rem(1);
    opacity: 0;
    transition: opacity 2s var(--ease-out);
    margin-left: 0.4rem;
    &.isResolved {
      opacity: 1;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.css-printout {
  font-size: rem(15);
  height: var(--height);
  transition: height 0.4s var(--ease-out);
  padding-inline: rem(12) 0;
  .summary {
    display: flex;
    justify-content: space-between;
    gap: rem(8);
    line-height: var(--input-height);
    font-weight: 500;
  }
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    transform-origin: center;
    transition: transform 0.2s var(--ease-out);
    transform: rotate(90deg);
    height: var(--input-height);
    width: calc(0.75 * var(--input-height));
  }
  &.isOpen {
    .arrow {
      transform: rotate(-90deg);
    }
  }

  button {
    display: block;
    text-align: left;
    width: 100%;
    padding: 0;
  }
  pre {
    margin: 0;
    overflow-x: auto;
    background: var(--bg-1);
    code {
      font-family: "Publico Text Mono", monospace;
      font-size: rem(14);
      display: block;
      padding: rem(16);
    }
  }
}
