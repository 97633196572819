@import "variables";

@mixin show-excerpt() {
  .excerpt {
    visibility: visible;
    position: static;
  }
}
@mixin credits-headings() {
  h3,
  strong,
  b {
    @content;
  }
}

.index {
  padding-block-end: 2rem;
  
  &.fontsLoading {
    transition: opacity 0.25s var(--ease-out);
    opacity: 0;
    .card {
      .category,
      .title,
      .excerpt,
      .byline {
        opacity: 0;
        transition: opacity 0.25s var(--ease-out);
      }
    }
  }
  &.fontsReady {
    opacity: 1;
    .card {
      .category,
      .title,
      .excerpt,
      .byline {
        transition: opacity 0.5s calc(0.1s * var(--index) + var(--delay-offset)) var(--ease-out);
        opacity: 1;
      }
    }
  }
  
  &.loader {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    height: 1px;
    overflow: hidden;
  }

  .articles {
    display: grid;
    margin-inline: auto;
    padding-inline: var(--site-padding);
    margin-block: 6rem;
    @include max-width($md) {
      margin-block: 2rem 6rem;
    }
    @include min-width($md) {
      column-gap: var(--gutter);
      grid-template-columns: repeat(12, 1fr);
    }
  }

  .nameplate {
    padding-inline: rem(20);
    margin: auto;
    display: flex;
    justify-content: center;
    max-width: 100%;
    @include max-width($md) {
      margin-block: 2rem;
    }
    svg {
      max-width: var(--nameplate-width);
      width: 100%;
      height: 100%;
    }
  }

  .card {
    display: block;
    text-decoration: none;
    
    &:nth-child(1) { --index: 1 }
    &:nth-child(2) { --index: 2 }
    &:nth-child(3) { --index: 3 }
    &:nth-child(4) { --index: 4 }
    &:nth-child(5) { --index: 5 }
    &:nth-child(6) { --index: 6 }

    .category,
    .title,
    .byline,
    .excerpt {
      margin-block: 0;
      white-space: pre-wrap;
    }
    .category { --delay-offset: 0.05s }
    .title { --delay-offset: 0s }
    .byline { --delay-offset: 0.15s }
  }
  .excerpt {
    visibility: hidden;
    position: absolute;
  }
  .notes {
    counter-reset: footnote;
    padding-inline: var(--site-padding);
    display: grid;
    row-gap: 1rem;
    column-gap: var(--gutter);

    font-size: rem(16);
    line-height: 1.4;

    @include min-width($md) {
      font-size: rem(18);
      grid-template-columns: repeat(2, 1fr);
    }

    .credits-title {
      margin: 0;
      @include min-width($md) {
        grid-column: 2;
        grid-row: 1;
        align-self: end;
      }
    }
    .editors-note {
      @include min-width($md) {
        grid-column: 1;
      }
      & > p:first-child {
        margin-block-start: 0;
      }
    }

    h3 {
      font: inherit;
      margin: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    footer {
      white-space: pre-wrap;
    }
  }
  .editors-note {
    p {
      margin-block: 1rem;
    }
  }
  .editors-note-title {
    margin-block: 0;
  }
  .note-byline {
    margin-block: 0;
    font-style: normal;
  }
  .credits {
    h3,
    footer {
      margin-block-start: 1em;
    }
    footer {
      margin-block-end: 2em;
    }
    @include credits-headings {
      font-weight: 700;
    }
  }
  aside {
    --font-size: #{rem(12)};
    font-weight: var(--weight-sidebar);
    @include min-width($lg) {
      --font-size: #{rem(14)};
    }
    ol {
      margin-block: 0;
      padding-inline: 0;
      li {
        position: relative;
        margin-left: 0.5em;
        padding-left: 0.5em;
        scroll-snap-align: start;
        line-height: 1.4;
        margin-block-end: rem(4);
        &:target {
          color: var(--fg-1);
        }
        @include max-width($lg) {
          padding-right: 2rem;
          flex: 0 0 auto;
        }
        list-style: none;
        counter-increment: footnote;
        &::before {
          position: absolute;
          left: -0.4rem;
          font-feature-settings: "sups" 1;
          content: counter(footnote) " ";
        }
      }
    }
  }

  &[data-layout="Feature"] {
    font-family: "Feature Text";
    --nameplate-width: #{rem(186)};
    @include min-width($md) {
      --nameplate-width: #{rem(428)};
    }
    .notes,
    .articles {
      @include min-width($md) {
        @include max-width($xxl) {
          padding-inline: calc(var(--site-padding) * 3);
        }
      }
    }
    .articles {
      row-gap: rem(48);
      @include min-width($xs) {
        row-gap: rem(64);
      }
      @include min-width($lg) {
        row-gap: rem(80);
      }
    }
    @include show-excerpt;
    .category {
      font-family: "Feature Text";
      font-weight: 700;
      font-size: rem(15);
      line-height: 1.4;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      @include min-width($md) {
        font-family: "Feature Text";
        font-weight: 700;
        font-size: rem(18);
        letter-spacing: 0.01em;
        margin-block-end: rem(15);
      }
    }

    .title {
      font-family: "Feature Deck";
      font-weight: 700;
      font-size: rem(36);
      line-height: 1.1;
      letter-spacing: -0.01em;
      @include min-width($md) {
        font-family: "Feature Display";
        font-size: rem(80);
        letter-spacing: 0.005em;
      }
    }
    .excerpt {
      --delay-offset: 0.1s;
      font-family: "Feature Text";
      font-style: italic;
      font-weight: 400;
      font-size: rem(18);
      line-height: calc(18 / 15);
      margin-block: rem(10);
      @include min-width($md) {
        font-size: rem(28);
        font-style: italic;
        font-weight: 400;
        line-height: calc(34 / 28);
        letter-spacing: 0em;
        margin-block: rem(20);
      }
    }
    .byline {
      font-family: "Feature Text";
      font-weight: 400;
      font-size: rem(21);
      line-height: 1.222;
      @include min-width($md) {
        font-family: "Feature Deck";
        font-style: normal;
        font-weight: 400;
        font-size: rem(32);
        line-height: 1.1875;
      }
    }
    .card {
      &:nth-child(odd) {
        @include min-width($md) {
          grid-column: 1 / span 10;
        }
        @include min-width($lg) {
          grid-column: 1 / span 7;
        }
      }
      &:nth-child(even) {
        padding-inline-start: 3rem;
        @include min-width($md) {
          grid-column: 3 / span 10;
        }
        @include min-width($lg) {
          grid-column: 6 / span 7;
        }
      }
    }
    .credits {
      @include credits-headings {
        font-weight: 800;
      }
    }
  }

  &[data-layout="Graphik"] {
    font-family: "Guardian Egyptian Text";
    --nameplate-width: #{rem(300)};
    @include min-width($md) {
      --nameplate-width: #{rem(438)};
    }
    .articles {
      row-gap: rem(48);
      @include min-width($xs) {
        row-gap: rem(64);
      }
      @include min-width($lg) {
        row-gap: rem(96);
      }
    }
    @include show-excerpt;
    .card {
      text-align: center;
      @include min-width($md) {
        grid-column: span 12;
      }
    }
    .category {
      font-family: "Graphik Wide";
      font-weight: 600;
      font-size: rem(20);
      line-height: 1.4;
      letter-spacing: 0.02em;
      @include min-width($md) {
        font-family: "Graphik Wide";
        font-size: rem(26);
        line-height: calc(31 / 26);
        letter-spacing: 0.005em;
      }
    }

    .title {
      font-family: "Graphik Wide";
      font-style: normal;
      font-weight: 800;
      font-size: rem(38);
      line-height: 1.1;
      letter-spacing: -0.01em;
      max-width: 18ch;
      margin-inline: auto;
      margin-block: rem(8);
      @include min-width($md) {
        font-size: rem(72);
        line-height: 1;
        margin-block: rem(12);
      }
    }

    .byline {
      font-family: "Graphik Wide";
      font-style: normal;
      font-weight: 600;
      font-size: rem(20);
      line-height: calc(24 / 20);
      @include min-width($md) {
        font-size: rem(32);
        line-height: 1.1875;
        letter-spacing: 0.005em;
      }
    }
    .excerpt {
      --delay-offset: 0.1s;
      font-family: "Graphik Wide";
      font-size: rem(18);
      font-weight: 500;
      line-height: 1;
      margin-block: rem(10) rem(12);
      @include min-width($md) {
        font-size: rem(26);
        line-height: calc(31 / 26);
        margin-block: rem(20);
      }
    }
    .editors-note-title,
    .editors-note {
      max-width: rem(550);
    }
  }
  &[data-layout="Antique No 6"] {
    font-family: "Austin News Text";
    --nameplate-width: #{rem(315)};
    @include min-width($md) {
      --nameplate-width: #{rem(635)};
    }

    .articles {
      row-gap: rem(48);
      @include min-width($xs) {
        row-gap: rem(64);
      }
      @include min-width($lg) {
        row-gap: rem(96);
      }
    }
    .card {
      text-align: center;
      @include min-width($md) {
        grid-column: span 6;
      }
    }
    .title {
      font-family: "Antique No 6";
      font-style: normal;
      font-weight: 300;
      font-size: rem(32);
      line-height: 1.1;
      margin-block: rem(5) rem(10);
      @include min-width($md) {
        font-size: rem(48);
        line-height: 1.19;
        letter-spacing: -0.02em;
        margin-block: rem(10);
      }
    }

    .byline {
      font-family: "Antique No 6";
      font-style: normal;
      font-weight: 500;
      font-size: rem(20);
      line-height: 1.4;
      @include min-width($md) {
        font-size: rem(28);
        line-height: 1.2142857143;
        letter-spacing: 0.005em;
      }
    }

    .category {
      font-family: "Antique No 6";
      font-style: normal;
      font-weight: 500;
      font-size: rem(20);
      line-height: 1.4;
      @include min-width($md) {
        font-size: rem(26);
        line-height: calc(31 / 26);
        letter-spacing: 0.005em;
      }
    }
    .editors-note-title,
    .editors-note {
      max-width: rem(525);
    }
    .credits-title,
    .credits {
      text-align: center;
    }
  }
  &[data-layout="Original Sans"] {
    font-family: "Caslon Ionic";
    --nameplate-width: #{rem(350)};
    @include min-width($md) {
      --nameplate-width: #{rem(875)};
    }
    .articles {
      row-gap: rem(48);
      @include min-width($xs) {
        row-gap: rem(64);
      }
      @include min-width($lg) {
        row-gap: rem(96);
      }
      @include min-width($xl) {
        row-gap: rem(120);
      }
    }
    .card {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;
      column-gap: 1rem;
      @include min-width($md) {
        column-gap: 2rem;
        grid-column: span 12;
      }
    }
    .title {
      margin-block-start: rem(4);
      font-family: "Original Sans";
      font-style: normal;
      font-weight: 400;
      font-size: rem(22);
      line-height: 0.9;
      letter-spacing: -0.02em;
      grid-column: 1 / -1;
      white-space: pre-wrap;
      text-transform: uppercase;
      @include min-width($xs) {
        font-size: rem(24);
      }
      @include min-width($sm) {
        font-size: rem(28);
      }
      @include min-width($md) {
        font-size: rem(32);
        line-height: 0.8;
        margin-block-start: rem(8);
      }
      @include min-width($lg) {
        font-size: rem(48);
        margin-block-start: rem(12);
      }
      @include min-width($xl) {
        font-size: rem(80);
        margin-block-start: rem(20);
      }
      b {
        display: inline-block;
        font-size: rem(42);
        font-weight: 400;
        line-height: 0.84;
        margin-left: -0.015em;
        transform: translateY(0.025em);
        @include min-width($xs) {
          font-size: rem(48);
        }
        @include min-width($sm) {
          font-size: rem(64);
        }
        @include min-width($md) {
          font-size: rem(96);
          line-height: 0.74;
        }
        @include min-width($lg) {
          font-size: rem(120);
        }
        @include min-width($xl) {
          font-size: rem(184);
        }
      }
    }

    .byline {
      font-family: "Marr Sans";
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
      font-style: normal;
      font-weight: 500;
      font-size: rem(13);
      line-height: 1.2;
      letter-spacing: 0.01em;
      text-align: left;
      @include min-width($md) {
        font-size: rem(18);
      }
      @include min-width($lg) {
        font-size: rem(26);
        letter-spacing: 0.005em;
      }
    }

    .category {
      font-family: "Marr Sans";
      font-style: normal;
      font-size: rem(13);
      line-height: 1.2;
      font-weight: 600;
      @include min-width($md) {
        font-size: rem(18);
      }
      @include min-width($lg) {
        font-size: rem(26);
      }
      text-transform: uppercase;
      letter-spacing: 0.005em;
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
    }
    .note-byline {
      font-family: "Marr Sans";
      font-style: normal;
      font-weight: 500;
      font-size: rem(28);
      line-height: calc(34 / 28);
      letter-spacing: 0.01em;
    }
    .editors-note-title {
      font-family: "Original Sans";
      font-style: normal;
      font-weight: 400;
      line-height: 1.28;
      font-size: rem(28);
      @include min-width($md) {
        font-size: rem(60);
      }
    }
    .credits,
    .credits-title {
      font-family: "Marr Sans";
      font-style: normal;
      font-weight: 500;
      line-height: 1.4;
    }
    .credits-title {
      font-size: rem(28);
    }
    .credits {
      font-family: "Marr Sans";
      @include credits-headings {
        font-weight: inherit;
      }
    }
  }
  &[data-layout="Seance"] {
    font-family: "Terza Reader";
    --nameplate-width: #{rem(230)};
    @include min-width($md) {
      --nameplate-width: #{rem(428)};
    }
    .card {
      position: relative;
      text-align: center;
      grid-column: 1 / -1;
      padding-block: rem(25) rem(35);
      @include min-width($md) {
        padding-block: rem(50) rem(60);
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          max-width: rem(480);
          left: 50%;
          transform: translateX(-50%);
          border-top: 1px solid var(--fg-1);
        }
      }
    }
    .category {
      font-family: "Terza Reader";
      font-size: rem(15);
      line-height: 1.4;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      @include min-width($md) {
        font-size: rem(24);
        font-weight: 300;
        line-height: calc(34 / 24);
      }
    }
    .title {
      font-family: "Seance";
      font-weight: 400;
      font-size: rem(44);
      line-height: 1.08;
      @include min-width($md) {
        font-size: rem(110);
        line-height: 1;
      }
    }
    .byline {
      font-size: rem(18);
      line-height: 1.2;
      font-weight: 500;
      margin-block: rem(10) 0;
      @include min-width($md) {
        font-size: rem(30);
      }
    }
    .notes {
      line-height: 1.5;
    }
    .editors-note-title {
      font-family: "Seance";
      font-weight: 400;
      font-size: rem(44);
      line-height: 1.08;
      @include min-width($md) {
        font-size: rem(72);
        line-height: 1;
      }
    }
    .editors-note-title,
    .editors-note {
      max-width: rem(650);
    }
    .credits-title,
    .credits {
      text-align: center;
    }
  }
  &[data-layout="Le Jeune"] {
    --nameplate-width: #{rem(272)};
    @include min-width($md) {
      --nameplate-width: #{rem(768)};
    }
    .articles {
      @include max-width($md) {
        row-gap: rem(45);
      }
    }
    .card {
      @include min-width($md) {
        grid-column: 1 / span 7;
        &:nth-child(even) {
          grid-column: 6 / span 7;
        }
      }
    }
    .notes {
      font-family: "Feature Text";
      letter-spacing: 0.015em;
    }
    .title {
      font-family: "Le Jeune Deck";
      font-style: italic;
      font-size: rem(33);
      font-weight: 300;
      line-height: 1.1;
      letter-spacing: -0.01em;

      @include min-width($md) {
        font-size: rem(80);
      }
      b {
        font-weight: 900;
      }
    }
    .category,
    .byline {
      margin-left: rem(35);
      @include min-width($md) {
        margin-left: rem(108);
      }
    }
    .byline {
      margin-block-start: rem(8);
    }
    .category,
    .byline,
    .credits-title {
      font-family: "Le Jeune Text Stencil";
      font-style: normal;
      font-weight: 450;
      font-size: rem(16);
      line-height: 1.4;
      letter-spacing: 0.1em;
      max-width: 20ch;
      text-transform: uppercase;
      @include min-width($md) {
        font-size: rem(26);
        line-height: 1.3;
      }
    }
    .notes {
      letter-spacing: 0.015em;
    }
    .editors-note-title {
      font-family: "Le Jeune Deck";
      font-style: italic;
      font-weight: 400;
      font-size: rem(33);
      line-height: 1.1;
      letter-spacing: -0.01em;
      @include min-width($md) {
        font-size: rem(56);
      }
    }
    .credits {
      @include credits-headings {
        font-weight: 800;
      }
    }
  }
  &[data-layout="Orleans"] {
    --nameplate-width: #{rem(290)};
    @include min-width($md) {
      --nameplate-width: #{rem(535)};
    }
    .articles {
      gap: 4rem;
    }
    .card {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      @include min-width($md) {
        column-gap: var(--gutter);
      }
      &:nth-child(1) .title {
        grid-column: 1 / 9;
      }
      &:nth-child(2) .title {
        grid-column: 1 / 11;
      }
      &:nth-child(3) .title {
        grid-column: 1 / 10;
      }
      &:nth-child(4) .title {
        grid-column: 1 / 8;
      }
      &:nth-child(5) .title {
        grid-column: 3 / -1;
      }
      &:nth-child(6) .title {
        grid-column: 3 / 10;
      }
    }
    .notes {
      font-family: "Publico Text";
    }
    .editors-note-title,
    .title {
      font-family: "Orleans";
      font-style: normal;
      font-weight: 100;
      font-size: rem(30);
      line-height: 1.1;
      @include min-width($md) {
        font-size: rem(80);
        line-height: 1;
      }
    }
    .title {
      text-align: right;
      grid-column: 1 / -1;
      margin-block: rem(8) rem(12);
      @include min-width($md) {
        margin-block: rem(16) rem(24);
      }
    }
    .category,
    .credits-title {
      font-family: "Orleans";
      font-style: normal;
      font-weight: 900;
      font-size: rem(14);
      line-height: 1.4;
      letter-spacing: 0.33em;
      text-transform: uppercase;
      @include min-width($md) {
        font-size: rem(24);
        letter-spacing: 0.4em;
      }
    }
    .category,
    .byline {
      grid-column: 6 / -1;
    }
    .byline {
      font-family: "Orleans";
      font-style: italic;
      font-weight: 300;
      font-size: rem(18);
      line-height: calc(22 / 18);
      @include min-width($md) {
        font-size: rem(36);
        line-height: calc(43 / 36);
        letter-spacing: 0.01em;
      }
    }
    .credits-title {
      font-weight: 500;
    }
    .credits {
      font-size: rem(16);
      font-family: "Atlas Grotesk";
      line-height: 1.4;
      @include min-width($md) {
        font-size: rem(18);
      }
      @include credits-headings {
        font-weight: 700;
      }
    }
  }
  &[data-layout="Orientation"] {
    --nameplate-width: #{rem(245)};
    @include min-width($md) {
      --nameplate-width: #{rem(470)};
    }
    @include min-width($md) {
      padding-inline: 3rem;
    }
    .articles {
      gap: 4rem;
    }
    .card {
      grid-column: 1 / -1;
      position: relative;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      @include min-width($md) {
        column-gap: var(--gutter);
      }
    }
    .title {
      font-family: "Orientation";
      font-style: normal;
      font-weight: 400;
      font-size: rem(38);
      line-height: 0.9;
      letter-spacing: -0.01em;
      grid-column: 1 / -1;
      padding-left: rem(40);
      @include min-width($md) {
        font-size: rem(100);
        padding-left: rem(56);
      }
    }
    .byline {
      grid-column: 7 / -1;
      font-family: "Moulin";
      font-weight: 400;
      font-size: rem(16);
      line-height: 1.2;
      margin-top: rem(16);
      @include min-width($md) {
        font-size: rem(36);
        margin-top: rem(28);
      }
    }
    .category,
    .credits-title {
      font-family: "Orientation";
      font-style: normal;
      font-weight: 400;
      font-size: rem(16);
      line-height: 1.4;
      letter-spacing: 0.1em;
      text-align: right;
      padding: rem(3) rem(8) rem(3) rem(12);
      color: var(--bg);
      background: var(--fg);
      transform-origin: top right;
      width: min-content;
      text-transform: uppercase;
      @include min-width($md) {
        font-weight: 700;
        font-size: rem(26);
        letter-spacing: 0.2em;
      }
    }
    .category {
      position: absolute;
      right: 100%;
      transform: rotate(-90deg);
      @include min-width($md) {
        transform-origin: bottom right;
        bottom: 100%;
      }
    }
    .notes {
      font-family: "Moulin";
      line-height: 1.5;
      @include min-width($md) {
        font-size: rem(20);
      }
    }
    .credits {
      position: relative;
      padding-left: 3rem;
      @include min-width($md) {
        padding-left: 4rem;
      }
      @include credits-headings {
        font-weight: 500;
      }
    }
    .credits-title {
      margin: 0;
      transform: translateX(-100%) rotate(-90deg) translateX(-3rem);
      @include min-width($md) {
        transform: translateX(-100%) rotate(-90deg) translateX(-4rem);
      }
    }
    .editors-note-title {
      font-family: "Orientation";
      font-style: normal;
      font-weight: 400;
      font-size: rem(33);
      line-height: 1.1;
      letter-spacing: -0.01em;
      @include min-width($md) {
        font-size: rem(60);
        line-height: 1.28;
      }
    }
  }
  &[data-layout="Lyon"] {
    --nameplate-width: #{rem(320)};
    @include min-width($md) {
      --nameplate-width: #{rem(580)};
    }
    font-family: "Lyon Text";
    .articles {
      row-gap: 5rem;
      @include max-width($md) {
        margin-block-start: rem(48);
      }
    }
    .card {
      display: flex;
      flex-direction: column;
      text-align: center;
      @include min-width($md) {
        grid-column: span 6;
      }
    }
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: rem(32);
      line-height: 1.1;
      letter-spacing: -0.01em;
      @include min-width($md) {
        font-size: rem(55);
        letter-spacing: -0.02em;
      }
    }
    @include show-excerpt;
    .excerpt {
      --delay-offset: 0.2s;
      font-style: italic;
      font-weight: 450;
      font-size: rem(16);
      line-height: 1.4;
      order: 5;
      @include min-width($md) {
        font-size: rem(22);
      }
      max-width: 40ch;
      margin-inline: auto;
    }
    .byline,
    .category {
      font-style: normal;
      font-weight: 700;
      font-size: rem(18);
      line-height: calc(22 / 18);
      @include min-width($md) {
        font-size: rem(22);
        line-height: calc(26 / 22);
        letter-spacing: 0.005em;
      }
    }
    .category,
    .title {
      margin-block-end: rem(16);
      @include min-width($md) {
        margin-block-end: rem(32);
      }
    }
    .category {
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    .byline {
      margin-block-end: rem(32);
    }
    .credits,
    .credits-title {
      text-align: center;
    }
    .credits-title {
      @include max-width($md) {
        margin-block-start: 1rem;
      }
    }
    .credits {
      @include credits-headings {
        font-weight: inherit;
      }
    }
    .editors-note-title {
      font-weight: 400;
      font-size: rem(30);
      line-height: 1.1;
      text-align: center;
      letter-spacing: -0.01em;
      @include min-width($md) {
        font-size: rem(36);
        line-height: 1.28;
      }
    }
  }
  &[data-layout="Eugenia"] {
    --nameplate-width: #{rem(200)};
    @include min-width($md) {
      --nameplate-width: #{rem(350)};
    }
    counter-reset: article;
    .articles {
      row-gap: rem(64);
      @include min-width($lg) {
        row-gap: rem(140);
      }
    }
    .card {
      grid-column: span 6;
    }
    .category,
    .byline {
      display: none;
    }
    .title {
      text-align: center;
      font-family: "Eugenia Headline";
      font-weight: 300;
      font-size: rem(35);
      line-height: 1.2;
      @include min-width($lg) {
        font-size: rem(60);
      }
    }
    .title {
      counter-increment: article;
      &::before {
        white-space: pre-wrap;
        content: counter(article) ".\a";
      }
    }
    .editors-note-title,
    .credits-title {
      font-family: "Eugenia Headline";
      font-weight: 300;
      font-size: rem(32);
      line-height: 1.1;
      text-align: center;
      letter-spacing: 0.02em;
      @include min-width($lg) {
        font-size: rem(48);
        line-height: 1.2;
      }
    }
    .credits {
      text-align: center;
    }
    .notes {
      font-family: "Darby Serif Text";
    }
  }
  &[data-layout="Proxy"] {
    --nameplate-width: #{rem(300)};
    @include min-width($md) {
      --nameplate-width: #{rem(620)};
    }
    .card {
      position: relative;
      text-align: center;
      grid-column: 1 / -1;
      padding-bottom: 2rem;
      margin-bottom: 1.5rem;
      @include min-width($md) {
        padding-bottom: 3rem;
        margin-bottom: 2rem;
      }
      &::after {
        content: "";
        position: absolute;
        max-width: 36ch;
        width: calc(100% - var(--site-padding) * 2);
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 1px solid currentColor;
      }
    }
    .category {
      font-family: "Atlas Typewriter";
      font-style: normal;
      font-weight: 400;
      font-size: rem(14);
      line-height: 1.4;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      margin-bottom: rem(16);
      @include min-width($md) {
        font-size: rem(20);
        margin-bottom: rem(24);
      }
    }
    .title {
      font-family: "Proxy";
      font-style: normal;
      font-weight: 500;
      font-size: rem(52);
      line-height: 1;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      @include min-width($md) {
        font-size: rem(100);
      }
    }
    @include show-excerpt;
    .excerpt {
      --delay-offset: 0.1s;
      font-family: "Atlas Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: rem(16);
      line-height: 1.4;
      margin-block: rem(8) rem(8);
      @include min-width($md) {
        font-size: rem(28);
      }
    }
    .byline {
      font-family: "Atlas Typewriter";
      font-style: normal;
      font-weight: 400;
      font-size: rem(16);
      line-height: 1.4;
      @include min-width($md) {
        font-size: rem(24);
      }
    }
    .notes {
      font-family: "Atlas Grotesk";
      line-height: 1.5;
    }
    .editors-note-title {
      font-family: "Proxy";
      font-style: normal;
      font-weight: 500;
      font-size: rem(58);
      line-height: 1.1;
      text-align: center;
      letter-spacing: 0.02em;

      @include min-width($md) {
        font-size: rem(72);
      }
    }
    .credits-title {
      font-family: "Atlas Typewriter";
      font-style: normal;
      font-weight: 400;
      font-size: rem(16);
      line-height: calc(19 / 16);
      @include min-width($md) {
        font-size: rem(22);
        line-height: calc(26 / 22);
      }
    }
    .credits-title,
    .credits {
      text-align: center;
    }
  }
}
