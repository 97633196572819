/*
    This font software is the property of Commercial Type.

    You may not modify the font software, use it on another website, or install it on a computer.

    License information is available at http://commercialtype.com/eula
    For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

    Copyright (C) 2022 Schwartzco Inc.
    License: 2204-JUTXLZ
*/

@font-face {
  font-family: 'Action Condensed Bold';
  src: url("/fonts/ActionCondensedBold-Grade1-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedBold-Grade1-Web.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Bold';
  src: url("/fonts/ActionCondensedBold-Grade1Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedBold-Grade1Italic-Web.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Bold';
  src: url("/fonts/ActionCondensedBold-Grade2-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedBold-Grade2-Web.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Bold';
  src: url("/fonts/ActionCondensedBold-Grade2Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedBold-Grade2Italic-Web.woff") format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Bold';
  src: url("/fonts/ActionCondensedBold-Grade3-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedBold-Grade3-Web.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Bold';
  src: url("/fonts/ActionCondensedBold-Grade3Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedBold-Grade3Italic-Web.woff") format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Light';
  src: url("/fonts/ActionCondensedLight-Grade1-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedLight-Grade1-Web.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Light';
  src: url("/fonts/ActionCondensedLight-Grade1Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedLight-Grade1Italic-Web.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Light';
  src: url("/fonts/ActionCondensedLight-Grade2-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedLight-Grade2-Web.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Light';
  src: url("/fonts/ActionCondensedLight-Grade2Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedLight-Grade2Italic-Web.woff") format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Light';
  src: url("/fonts/ActionCondensedLight-Grade3-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedLight-Grade3-Web.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Light';
  src: url("/fonts/ActionCondensedLight-Grade3Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedLight-Grade3Italic-Web.woff") format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Medium';
  src: url("/fonts/ActionCondensedMedium-Grade1-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedMedium-Grade1-Web.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Medium';
  src: url("/fonts/ActionCondensedMedium-Grade1Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedMedium-Grade1Italic-Web.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Medium';
  src: url("/fonts/ActionCondensedMedium-Grade2-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedMedium-Grade2-Web.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Medium';
  src: url("/fonts/ActionCondensedMedium-Grade2Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedMedium-Grade2Italic-Web.woff") format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Medium';
  src: url("/fonts/ActionCondensedMedium-Grade3-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedMedium-Grade3-Web.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Medium';
  src: url("/fonts/ActionCondensedMedium-Grade3Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedMedium-Grade3Italic-Web.woff") format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Regular';
  src: url("/fonts/ActionCondensedRegular-Grade1-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedRegular-Grade1-Web.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Regular';
  src: url("/fonts/ActionCondensedRegular-Grade1Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedRegular-Grade1Italic-Web.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Regular';
  src: url("/fonts/ActionCondensedRegular-Grade2-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedRegular-Grade2-Web.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Regular';
  src: url("/fonts/ActionCondensedRegular-Grade2Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedRegular-Grade2Italic-Web.woff") format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Action Condensed Regular';
  src: url("/fonts/ActionCondensedRegular-Grade3-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedRegular-Grade3-Web.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Action Condensed Regular';
  src: url("/fonts/ActionCondensedRegular-Grade3Italic-Web.woff2") format('woff2'),
       url("/fonts/ActionCondensedRegular-Grade3Italic-Web.woff") format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Action Text Bright';
  src: url("/fonts/ActionTextBright-Bold-Web.woff2") format('woff2'),
       url("/fonts/ActionTextBright-Bold-Web.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Action Text Bright';
  src: url("/fonts/ActionTextBright-BoldItalic-Web.woff2") format('woff2'),
       url("/fonts/ActionTextBright-BoldItalic-Web.woff") format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Action Text Bright';
  src: url("/fonts/ActionTextBright-Extrabold-Web.woff2") format('woff2'),
       url("/fonts/ActionTextBright-Extrabold-Web.woff") format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Action Text Bright';
  src: url("/fonts/ActionTextBright-ExtraboldItalic-Web.woff2") format('woff2'),
       url("/fonts/ActionTextBright-ExtraboldItalic-Web.woff") format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Action Text Bright';
  src: url("/fonts/ActionTextBright-Medium-Web.woff2") format('woff2'),
       url("/fonts/ActionTextBright-Medium-Web.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Action Text Bright';
  src: url("/fonts/ActionTextBright-MediumItalic-Web.woff2") format('woff2'),
       url("/fonts/ActionTextBright-MediumItalic-Web.woff") format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Action Text Bright';
  src: url("/fonts/ActionTextBright-Regular-Web.woff2") format('woff2'),
       url("/fonts/ActionTextBright-Regular-Web.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Action Text Bright';
  src: url("/fonts/ActionTextBright-RegularItalic-Web.woff2") format('woff2'),
       url("/fonts/ActionTextBright-RegularItalic-Web.woff") format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Action Text Dark';
  src: url("/fonts/ActionTextDark-Bold-Web.woff2") format('woff2'),
       url("/fonts/ActionTextDark-Bold-Web.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Action Text Dark';
  src: url("/fonts/ActionTextDark-BoldItalic-Web.woff2") format('woff2'),
       url("/fonts/ActionTextDark-BoldItalic-Web.woff") format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Action Text Dark';
  src: url("/fonts/ActionTextDark-Extrabold-Web.woff2") format('woff2'),
       url("/fonts/ActionTextDark-Extrabold-Web.woff") format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Action Text Dark';
  src: url("/fonts/ActionTextDark-ExtraboldItalic-Web.woff2") format('woff2'),
       url("/fonts/ActionTextDark-ExtraboldItalic-Web.woff") format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Action Text Dark';
  src: url("/fonts/ActionTextDark-Medium-Web.woff2") format('woff2'),
       url("/fonts/ActionTextDark-Medium-Web.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Action Text Dark';
  src: url("/fonts/ActionTextDark-MediumItalic-Web.woff2") format('woff2'),
       url("/fonts/ActionTextDark-MediumItalic-Web.woff") format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Action Text Dark';
  src: url("/fonts/ActionTextDark-Regular-Web.woff2") format('woff2'),
       url("/fonts/ActionTextDark-Regular-Web.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Action Text Dark';
  src: url("/fonts/ActionTextDark-RegularItalic-Web.woff2") format('woff2'),
       url("/fonts/ActionTextDark-RegularItalic-Web.woff") format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Frame Head';
  src: url("/fonts/FrameHead-BoldItalic-Web.woff2") format('woff2'),
       url("/fonts/FrameHead-BoldItalic-Web.woff") format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Frame Head';
  src: url("/fonts/FrameHead-Italic-Web.woff2") format('woff2'),
       url("/fonts/FrameHead-Italic-Web.woff") format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Frame Head';
  src: url("/fonts/FrameHead-Medium-Web.woff2") format('woff2'),
       url("/fonts/FrameHead-Medium-Web.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Frame Head';
  src: url("/fonts/FrameHead-MediumItalic-Web.woff2") format('woff2'),
       url("/fonts/FrameHead-MediumItalic-Web.woff") format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Frame Head';
  src: url("/fonts/FrameHead-Roman-Web.woff2") format('woff2'),
       url("/fonts/FrameHead-Roman-Web.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Frame Head';
  src: url("/fonts/FrameHead-Semibold-Web.woff2") format('woff2'),
       url("/fonts/FrameHead-Semibold-Web.woff") format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Frame Head';
  src: url("/fonts/FrameHead-SemiboldItalic-Web.woff2") format('woff2'),
       url("/fonts/FrameHead-SemiboldItalic-Web.woff") format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Frame Text';
  src: url("/fonts/FrameText-Bold-Web.woff2") format('woff2'),
       url("/fonts/FrameText-Bold-Web.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Frame Text';
  src: url("/fonts/FrameText-BoldItalic-Web.woff2") format('woff2'),
       url("/fonts/FrameText-BoldItalic-Web.woff") format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Frame Text';
  src: url("/fonts/FrameText-Italic-Web.woff2") format('woff2'),
       url("/fonts/FrameText-Italic-Web.woff") format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Frame Text';
  src: url("/fonts/FrameText-Medium-Web.woff2") format('woff2'),
       url("/fonts/FrameText-Medium-Web.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Frame Text';
  src: url("/fonts/FrameText-MediumItalic-Web.woff2") format('woff2'),
       url("/fonts/FrameText-MediumItalic-Web.woff") format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Frame Text';
  src: url("/fonts/FrameText-Roman-Web.woff2") format('woff2'),
       url("/fonts/FrameText-Roman-Web.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Frame Text';
  src: url("/fonts/FrameText-Semibold-Web.woff2") format('woff2'),
       url("/fonts/FrameText-Semibold-Web.woff") format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Frame Text';
  src: url("/fonts/FrameText-SemiboldItalic-Web.woff2") format('woff2'),
       url("/fonts/FrameText-SemiboldItalic-Web.woff") format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-Black-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-Bold-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-Light-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-Medium-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-Medium-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-MediumItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-Regular-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-RegularNo2-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-RegularNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-RegularNo2Italic-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-RegularNo2Italic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-Thin-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra Display";
  src: url("/fonts/AlgebraDisplay-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/AlgebraDisplay-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-Black-Web.woff2") format("woff2"),
    url("/fonts/Algebra-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/Algebra-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-Bold-Web.woff2") format("woff2"),
    url("/fonts/Algebra-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Algebra-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/Algebra-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/Algebra-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-Medium-Web.woff2") format("woff2"),
    url("/fonts/Algebra-Medium-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/Algebra-MediumItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-Regular-Web.woff2") format("woff2"),
    url("/fonts/Algebra-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/Algebra-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-RegularNo2-Web.woff2") format("woff2"),
    url("/fonts/Algebra-RegularNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Algebra";
  src: url("/fonts/Algebra-RegularNo2Italic-Web.woff2") format("woff2"),
    url("/fonts/Algebra-RegularNo2Italic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-Black-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-Bold-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-Italic-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-Light-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-Medium-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-Roman-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-Thin-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Antique No 6";
  src: url("/fonts/AntiqueNo6-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/AntiqueNo6-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-Black-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-Bold-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-Light-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-Medium-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-Regular-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-Thin-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  }

  @font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-Black-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("/fonts/AtlasGrotesk-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasGrotesk-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-Bold-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-Light-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-Medium-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-Regular-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-Thin-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Atlas Typewriter";
  src: url("/fonts/AtlasTypewriter-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/AtlasTypewriter-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-Bold-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-Bold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-BoldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-Extrabold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-Fat-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-Fat-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-FatItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-FatItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-Italic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-Italic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-Light-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-Light-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-LightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-Medium-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-Medium-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-MediumItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-Roman-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-Roman-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-Semibold-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-Semibold-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-SemiboldItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-Ultra-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-Ultra-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Deck";
  src: url("/fonts/AustinNewsDeck-UltraItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsDeck-UltraItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Text";
  src: url("/fonts/AustinNewsText-Bold-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Text";
  src: url("/fonts/AustinNewsText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Text";
  src: url("/fonts/AustinNewsText-Fat-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsText-Fat-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Text";
  src: url("/fonts/AustinNewsText-FatItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsText-FatItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Text";
  src: url("/fonts/AustinNewsText-Italic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsText-Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Text";
  src: url("/fonts/AustinNewsText-ItalicNo2-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsText-ItalicNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Text";
  src: url("/fonts/AustinNewsText-Roman-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsText-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Text";
  src: url("/fonts/AustinNewsText-RomanNo2-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsText-RomanNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Text";
  src: url("/fonts/AustinNewsText-Semibold-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin News Text";
  src: url("/fonts/AustinNewsText-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinNewsText-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin Text";
  src: url("/fonts/AustinText-Bold-Web.woff2") format("woff2"),
    url("/fonts/AustinText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin Text";
  src: url("/fonts/AustinText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin Text";
  src: url("/fonts/AustinText-Fat-Web.woff2") format("woff2"),
    url("/fonts/AustinText-Fat-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin Text";
  src: url("/fonts/AustinText-FatItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinText-FatItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin Text";
  src: url("/fonts/AustinText-Italic-Web.woff2") format("woff2"),
    url("/fonts/AustinText-Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin Text";
  src: url("/fonts/AustinText-ItalicNo2-Web.woff2") format("woff2"),
    url("/fonts/AustinText-ItalicNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin Text";
  src: url("/fonts/AustinText-Roman-Web.woff2") format("woff2"),
    url("/fonts/AustinText-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin Text";
  src: url("/fonts/AustinText-RomanNo2-Web.woff2") format("woff2"),
    url("/fonts/AustinText-RomanNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin Text";
  src: url("/fonts/AustinText-Semibold-Web.woff2") format("woff2"),
    url("/fonts/AustinText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin Text";
  src: url("/fonts/AustinText-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/AustinText-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-Bold-Web.woff2") format("woff2"),
    url("/fonts/Austin-Bold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Austin-BoldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/Austin-Extrabold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/Austin-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-Fat-Web.woff2") format("woff2"),
    url("/fonts/Austin-Fat-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-FatItalic-Web.woff2") format("woff2"),
    url("/fonts/Austin-FatItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-Italic-Web.woff2") format("woff2"),
    url("/fonts/Austin-Italic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-Light-Web.woff2") format("woff2"),
    url("/fonts/Austin-Light-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/Austin-LightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-Medium-Web.woff2") format("woff2"),
    url("/fonts/Austin-Medium-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/Austin-MediumItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-Roman-Web.woff2") format("woff2"),
    url("/fonts/Austin-Roman-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-RomanItalic-Web.woff2") format("woff2"),
    url("/fonts/Austin-RomanItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-Semibold-Web.woff2") format("woff2"),
    url("/fonts/Austin-Semibold-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/Austin-SemiboldItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-Ultra-Web.woff2") format("woff2"),
    url("/fonts/Austin-Ultra-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Austin";
  src: url("/fonts/Austin-UltraItalic-Web.woff2") format("woff2"),
    url("/fonts/Austin-UltraItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  }

  @font-face {
  font-family: "Seance";
  src: url("/fonts/Seance-Graphic-Web.woff2") format("woff2"),
    url("/fonts/Seance-Graphic-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-Black-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-Bold-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-Bold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-BoldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-Italic-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-ItalicNo2-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-ItalicNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-Medium-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-Medium-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-MediumItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-Roman-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-RomanNo2-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-RomanNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-Semibold-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-Semibold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Deck";
  src: url("/fonts/BrunelDeck-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/BrunelDeck-SemiboldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Text Short";
  src: url("/fonts/BrunelTextShort-Bold-Web.woff2") format("woff2"),
    url("/fonts/BrunelTextShort-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Text Short";
  src: url("/fonts/BrunelTextShort-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/BrunelTextShort-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Text Short";
  src: url("/fonts/BrunelTextShort-Italic-Web.woff2") format("woff2"),
    url("/fonts/BrunelTextShort-Italic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Text Short";
  src: url("/fonts/BrunelTextShort-ItalicNo2-Web.woff2") format("woff2"),
    url("/fonts/BrunelTextShort-ItalicNo2-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Text Short";
  src: url("/fonts/BrunelTextShort-Medium-Web.woff2") format("woff2"),
    url("/fonts/BrunelTextShort-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Text Short";
  src: url("/fonts/BrunelTextShort-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/BrunelTextShort-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Text Short";
  src: url("/fonts/BrunelTextShort-Roman-Web.woff2") format("woff2"),
    url("/fonts/BrunelTextShort-Roman-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  }

/* 
<axes>
<axis tag="wght" name="Weight" minimum="400" maximum="700" default="400">
  <labelname xml:lang="en">Weight</labelname>
  <400 = regular>
  <500 = Medium>
  <600 = Semibold>
  <700 = Bold>
</axis>
<axis tag="opsz" name="Optical Size" minimum="10" maximum="72" default="10">
  <labelname xml:lang="en">Optical Size</labelname>
  <10 = Text>
  <24 = Deck>
  <72 = Head>
</axis>
</axes>
*/

@font-face {
  font-family: "Frame";
  src: url("/fonts/Frame-Italic-VF-Web.woff2") format("woff2-variations"),
    url("/fonts/Frame-Italic-VF-Web.woff") format("woff-variations");
  font-weight: 400 700;
  font-style: italic;
}

@font-face {
  font-family: "Brunel Text Short";
  src: url("/fonts/BrunelTextShort-RomanNo2-Web.woff2") format("woff2"),
    url("/fonts/BrunelTextShort-RomanNo2-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Text Short";
  src: url("/fonts/BrunelTextShort-Semibold-Web.woff2") format("woff2"),
    url("/fonts/BrunelTextShort-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Brunel Text Short";
  src: url("/fonts/BrunelTextShort-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/BrunelTextShort-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-Black-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-Bold-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-Light-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-Medium-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-Regular-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-Thin-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela Deck";
  src: url("/fonts/CanelaDeck-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/CanelaDeck-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-Black-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-Bold-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-Medium-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-Regular-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-RegularItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-RegularNo2-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-RegularNo2-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-RegularNo2Italic-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-RegularNo2Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-Semibold-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Display";
  src: url("/fonts/CaponiDisplay-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiDisplay-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-Black-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-Bold-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-Medium-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-Regular-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-Regular-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-RegularItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-RegularNo2-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-RegularNo2-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-RegularNo2Italic-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-RegularNo2Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-Semibold-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Slab";
  src: url("/fonts/CaponiSlab-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiSlab-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Text";
  src: url("/fonts/CaponiText-Bold-Web.woff2") format("woff2"),
    url("/fonts/CaponiText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Text";
  src: url("/fonts/CaponiText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Text";
  src: url("/fonts/CaponiText-Medium-Web.woff2") format("woff2"),
    url("/fonts/CaponiText-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Text";
  src: url("/fonts/CaponiText-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiText-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Text";
  src: url("/fonts/CaponiText-Regular-Web.eot");
  src: url("/fonts/CaponiText-Regular-Web.eot?#iefix") format("embedded-opentype"),
    url("/fonts/CaponiText-Regular-Web.woff2") format("woff2"),
    url("/fonts/CaponiText-Regular-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Text";
  src: url("/fonts/CaponiText-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiText-RegularItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Text";
  src: url("/fonts/CaponiText-RegularNo2-Web.woff2") format("woff2"),
    url("/fonts/CaponiText-RegularNo2-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Text";
  src: url("/fonts/CaponiText-RegularNo2Italic-Web.woff2") format("woff2"),
    url("/fonts/CaponiText-RegularNo2Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Text";
  src: url("/fonts/CaponiText-Semibold-Web.woff2") format("woff2"),
    url("/fonts/CaponiText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caponi Text";
  src: url("/fonts/CaponiText-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaponiText-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-Black-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-Bold-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-Fat-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-Fat-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-FatItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-FatItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-Hairline-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-Hairline-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-HairlineItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-HairlineItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-Light-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-Medium-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-Regular-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-RegularNo2-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-RegularNo2-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-RegularNo2Italic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-RegularNo2Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-Semibold-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-Thin-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-Thin-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Doric";
  src: url("/fonts/CaslonDoric-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonDoric-ThinItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Ionic";
  src: url("/fonts/CaslonIonic-Bold-Web.woff2") format("woff2"),
    url("/fonts/CaslonIonic-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Ionic";
  src: url("/fonts/CaslonIonic-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonIonic-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Ionic";
  src: url("/fonts/CaslonIonic-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/CaslonIonic-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Ionic";
  src: url("/fonts/CaslonIonic-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonIonic-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Ionic";
  src: url("/fonts/CaslonIonic-Medium-Web.woff2") format("woff2"),
    url("/fonts/CaslonIonic-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Ionic";
  src: url("/fonts/CaslonIonic-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonIonic-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Ionic";
  src: url("/fonts/CaslonIonic-Regular-Web.woff2") format("woff2"),
    url("/fonts/CaslonIonic-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Caslon Ionic";
  src: url("/fonts/CaslonIonic-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/CaslonIonic-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-Bold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-Extralight-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-Extralight-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-Light-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-Regular-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-Semibold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Chiswick Deck";
  src: url("/fonts/ChiswickDeck-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickDeck-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-Black-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-Bold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-Fat-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-Fat-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-FatItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-FatItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-Light-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-Regular-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-Semibold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-Thin-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-XLight-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-XLight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Grotesque";
  src: url("/fonts/ChiswickGrotesque-XLightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickGrotesque-XLightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Headline";
  src: url("/fonts/ChiswickHeadline-Bold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickHeadline-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Headline";
  src: url("/fonts/ChiswickHeadline-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickHeadline-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Headline";
  src: url("/fonts/ChiswickHeadline-Light-Web.woff2") format("woff2"),
    url("/fonts/ChiswickHeadline-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Headline";
  src: url("/fonts/ChiswickHeadline-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickHeadline-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Headline";
  src: url("/fonts/ChiswickHeadline-Regular-Web.woff2") format("woff2"),
    url("/fonts/ChiswickHeadline-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Headline";
  src: url("/fonts/ChiswickHeadline-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickHeadline-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Headline";
  src: url("/fonts/ChiswickHeadline-Semibold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickHeadline-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Headline";
  src: url("/fonts/ChiswickHeadline-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickHeadline-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Headline";
  src: url("/fonts/ChiswickHeadline-XLight-Web.woff2") format("woff2"),
    url("/fonts/ChiswickHeadline-XLight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Chiswick Headline";
  src: url("/fonts/ChiswickHeadline-XLightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickHeadline-XLightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-Black-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-Bold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-Light-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-Regular-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-Semibold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-Thin-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("/fonts/ChiswickSansText-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSansText-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-Bold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-Fat-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-Fat-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-FatItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-FatItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-Light-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-Regular-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-Semibold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-Thin-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-XLight-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-XLight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Sans";
  src: url("/fonts/ChiswickSans-XLightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickSans-XLightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Text";
  src: url("/fonts/ChiswickText-Bold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Text";
  src: url("/fonts/ChiswickText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Text";
  src: url("/fonts/ChiswickText-Extralight-Web.woff2") format("woff2"),
    url("/fonts/ChiswickText-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Text";
  src: url("/fonts/ChiswickText-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickText-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Text";
  src: url("/fonts/ChiswickText-Light-Web.woff2") format("woff2"),
    url("/fonts/ChiswickText-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Text";
  src: url("/fonts/ChiswickText-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickText-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Text";
  src: url("/fonts/ChiswickText-Regular-Web.woff2") format("woff2"),
    url("/fonts/ChiswickText-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Text";
  src: url("/fonts/ChiswickText-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickText-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Text";
  src: url("/fonts/ChiswickText-Semibold-Web.woff2") format("woff2"),
    url("/fonts/ChiswickText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chiswick Text";
  src: url("/fonts/ChiswickText-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/ChiswickText-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-Black-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-Bold-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-Light-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-Medium-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-Regular-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-Thin-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-XLight-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-XLight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans Poster";
  src: url("/fonts/DarbySansPoster-XLightItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySansPoster-XLightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-Black-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-Bold-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-Light-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-Medium-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-Regular-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-Thin-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-XLight-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-XLight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Sans";
  src: url("/fonts/DarbySans-XLightItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySans-XLightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-Black-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-Bold-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-Light-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-Medium-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-Regular-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-Semibold-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Display";
  src: url("/fonts/DarbySerifDisplay-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifDisplay-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Text";
  src: url("/fonts/DarbySerifText-Bold-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Text";
  src: url("/fonts/DarbySerifText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Text";
  src: url("/fonts/DarbySerifText-Medium-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifText-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Text";
  src: url("/fonts/DarbySerifText-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifText-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Text";
  src: url("/fonts/DarbySerifText-Regular-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifText-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Text";
  src: url("/fonts/DarbySerifText-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifText-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Text";
  src: url("/fonts/DarbySerifText-Semibold-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Darby Serif Text";
  src: url("/fonts/DarbySerifText-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/DarbySerifText-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-Black-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-Bold-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-Light-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-Medium-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-Regular-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-Thin-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Ionic";
  src: url("/fonts/DuplicateIonic-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateIonic-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-Black-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-Bold-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-Light-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-Medium-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-Regular-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-Thin-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("/fonts/DuplicateSans-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSans-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-Black-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-Bold-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-Light-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-Medium-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-Regular-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-Thin-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Duplicate Slab";
  src: url("/fonts/DuplicateSlab-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/DuplicateSlab-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenia Headline";
  src: url("/fonts/Eugenia-HeadlineLight-Web.woff2") format("woff2"),
    url("/fonts/Eugenia-HeadlineLight-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Eugenia Headline";
  src: url("/fonts/Eugenia-HeadlineRegular-Web.woff2") format("woff2"),
    url("/fonts/Eugenia-HeadlineRegular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Eugenia Poster";
  src: url("/fonts/Eugenia-PosterLight-Web.woff2") format("woff2"),
    url("/fonts/Eugenia-PosterLight-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Eugenia Poster";
  src: url("/fonts/Eugenia-PosterRegular-Web.woff2") format("woff2"),
    url("/fonts/Eugenia-PosterRegular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Eugenio Sans Text";
  src: url("/fonts/EugenioSansText-Bold-Web.woff2") format("woff2"),
    url("/fonts/EugenioSansText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans Text";
  src: url("/fonts/EugenioSansText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSansText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans Text";
  src: url("/fonts/EugenioSansText-Light-Web.woff2") format("woff2"),
    url("/fonts/EugenioSansText-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans Text";
  src: url("/fonts/EugenioSansText-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSansText-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans Text";
  src: url("/fonts/EugenioSansText-Medium-Web.woff2") format("woff2"),
    url("/fonts/EugenioSansText-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans Text";
  src: url("/fonts/EugenioSansText-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSansText-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans Text";
  src: url("/fonts/EugenioSansText-Regular-Web.woff2") format("woff2"),
    url("/fonts/EugenioSansText-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans Text";
  src: url("/fonts/EugenioSansText-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSansText-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-Black-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-Bold-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-Book-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-Book-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-BookItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-BookItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-Extralight-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-Light-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-Medium-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-Medium-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-MediumItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-Regular-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-Regular-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-RegularItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-Thin-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Sans";
  src: url("/fonts/EugenioSans-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSans-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-Black-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-Bold-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-Demi-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-Demi-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-DemiItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-DemiItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-Light-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-Medium-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-Regular-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Eugenio Serif";
  src: url("/fonts/EugenioSerif-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/EugenioSerif-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Deck";
  src: url("/fonts/FeatureDeck-Bold-Web.woff2") format("woff2"),
    url("/fonts/FeatureDeck-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Deck";
  src: url("/fonts/FeatureDeck-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDeck-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Deck";
  src: url("/fonts/FeatureDeck-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/FeatureDeck-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Deck";
  src: url("/fonts/FeatureDeck-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDeck-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Deck";
  src: url("/fonts/FeatureDeck-Light-Web.woff2") format("woff2"),
    url("/fonts/FeatureDeck-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Deck";
  src: url("/fonts/FeatureDeck-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDeck-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Deck";
  src: url("/fonts/FeatureDeck-Medium-Web.woff2") format("woff2"),
    url("/fonts/FeatureDeck-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Deck";
  src: url("/fonts/FeatureDeck-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDeck-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Deck";
  src: url("/fonts/FeatureDeck-Regular-Web.woff2") format("woff2"),
    url("/fonts/FeatureDeck-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Deck";
  src: url("/fonts/FeatureDeck-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDeck-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-Bold-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-Extralight-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-Light-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-Medium-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-Regular-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-Ultra-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-Ultra-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Feature Display";
  src: url("/fonts/FeatureDisplay-UltraItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureDisplay-UltraItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Feature Text";
  src: url("/fonts/FeatureText-Bold-Web.woff2") format("woff2"),
    url("/fonts/FeatureText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Text";
  src: url("/fonts/FeatureText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Text";
  src: url("/fonts/FeatureText-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/FeatureText-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Text";
  src: url("/fonts/FeatureText-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureText-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Text";
  src: url("/fonts/FeatureText-Regular-Web.woff2") format("woff2"),
    url("/fonts/FeatureText-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Feature Text";
  src: url("/fonts/FeatureText-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/FeatureText-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Frame Head';
  src: url("/fonts/FrameHead-Bold-Web.woff2") format('woff2'),
       url("/fonts/FrameHead-Bold-Web.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-Black-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-Bold-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-Extralight-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-Light-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-Medium-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-Regular-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-Semibold-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-Super-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-SuperItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-SuperItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-Thin-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Compact";
  src: url("/fonts/GraphikCompact-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCompact-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-Black-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-Bold-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-Extralight-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-Light-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-Medium-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-Regular-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-Semibold-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-Super-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-SuperItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-SuperItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-Thin-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Condensed";
  src: url("/fonts/GraphikCondensed-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikCondensed-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-Black-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-Bold-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-Extralight-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-Light-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-Medium-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-Regular-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-Semibold-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-Super-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-SuperItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-SuperItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-Thin-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Wide";
  src: url("/fonts/GraphikWide-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikWide-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-Black-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-Bold-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-Extralight-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-Light-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-Medium-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-Regular-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-Semibold-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-Super-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-SuperItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-SuperItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-Thin-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Graphik X Condensed";
  src: url("/fonts/GraphikXCondensed-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/GraphikXCondensed-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Black-Web.woff2") format("woff2"),
    url("/fonts/Graphik-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Bold-Web.woff2") format("woff2"),
    url("/fonts/Graphik-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Extralight-Web.woff2") format("woff2"),
    url("/fonts/Graphik-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Light-Web.woff2") format("woff2"),
    url("/fonts/Graphik-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Medium-Web.woff2") format("woff2"),
    url("/fonts/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Regular-Web.woff2") format("woff2"),
    url("/fonts/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Semibold-Web.woff2") format("woff2"),
    url("/fonts/Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Super-Web.woff2") format("woff2"),
    url("/fonts/Graphik-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-SuperItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-SuperItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Thin-Web.woff2") format("woff2"),
    url("/fonts/Graphik-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-Black-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-Bold-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-Hairline-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-Hairline-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-HairlineItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-HairlineItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-Light-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-Medium-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-Regular-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-Semibold-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-Thin-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-Thin-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Headline";
  src: url("/fonts/GuardianEgyptian-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianEgyptian-ThinItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Text";
  src: url("/fonts/GuardianTextEgyptian-Black-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextEgyptian-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Text";
  src: url("/fonts/GuardianTextEgyptian-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextEgyptian-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Text";
  src: url("/fonts/GuardianTextEgyptian-Bold-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextEgyptian-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Text";
  src: url("/fonts/GuardianTextEgyptian-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextEgyptian-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Text";
  src: url("/fonts/GuardianTextEgyptian-Medium-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextEgyptian-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Text";
  src: url("/fonts/GuardianTextEgyptian-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextEgyptian-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Text";
  src: url("/fonts/GuardianTextEgyptian-Regular-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextEgyptian-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Egyptian Text";
  src: url("/fonts/GuardianTextEgyptian-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextEgyptian-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-Black-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-Bold-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-Hairline-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-Hairline-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-HairlineItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-HairlineItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-Light-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-Medium-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-Regular-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-Semibold-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-Thin-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-Thin-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-ThinItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-Ultra-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-Ultra-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline Condensed";
  src: url("/fonts/GuardianSansCond-UltraItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSansCond-UltraItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-Black-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-Bold-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-Hairline-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-Hairline-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-HairlineItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-HairlineItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-Light-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-Medium-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-Regular-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-Semibold-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-Thin-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-Thin-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-ThinItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-Ultra-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-Ultra-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Headline";
  src: url("/fonts/GuardianSans-UltraItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianSans-UltraItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Text";
  src: url("/fonts/GuardianTextSans-Black-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextSans-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Text";
  src: url("/fonts/GuardianTextSans-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextSans-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Text";
  src: url("/fonts/GuardianTextSans-Bold-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextSans-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Text";
  src: url("/fonts/GuardianTextSans-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextSans-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Text";
  src: url("/fonts/GuardianTextSans-Medium-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextSans-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Text";
  src: url("/fonts/GuardianTextSans-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextSans-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Text";
  src: url("/fonts/GuardianTextSans-Regular-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextSans-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Guardian Sans Text";
  src: url("/fonts/GuardianTextSans-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/GuardianTextSans-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-Black-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-Bold-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-Light-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-Medium-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-Regular-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-Semibold-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck";
  src: url("/fonts/LeJeuneDeck-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck Stencil";
  src: url("/fonts/LeJeuneDeck-Stencil-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-Stencil-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Deck Stencil";
  src: url("/fonts/LeJeuneDeck-StencilItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneDeck-StencilItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Text Stencil";
  src: url("/fonts/LeJeuneText-Stencil-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneText-Stencil-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Text Stencil";
  src: url("/fonts/LeJeuneText-StencilItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneText-StencilItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Text";
  src: url("/fonts/LeJeuneText-Black-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneText-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Text";
  src: url("/fonts/LeJeuneText-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneText-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Text";
  src: url("/fonts/LeJeuneText-Bold-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Text";
  src: url("/fonts/LeJeuneText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Text";
  src: url("/fonts/LeJeuneText-Regular-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneText-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Text";
  src: url("/fonts/LeJeuneText-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneText-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Text";
  src: url("/fonts/LeJeuneText-Semibold-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Le Jeune Text";
  src: url("/fonts/LeJeuneText-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/LeJeuneText-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Display";
  src: url("/fonts/LyonDisplay-Black-Web.woff2") format("woff2"),
    url("/fonts/LyonDisplay-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Display";
  src: url("/fonts/LyonDisplay-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/LyonDisplay-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Display";
  src: url("/fonts/LyonDisplay-Bold-Web.woff2") format("woff2"),
    url("/fonts/LyonDisplay-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Display";
  src: url("/fonts/LyonDisplay-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/LyonDisplay-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Display";
  src: url("/fonts/LyonDisplay-Light-Web.woff2") format("woff2"),
    url("/fonts/LyonDisplay-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Display";
  src: url("/fonts/LyonDisplay-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/LyonDisplay-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Display";
  src: url("/fonts/LyonDisplay-Medium-Web.woff2") format("woff2"),
    url("/fonts/LyonDisplay-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Display";
  src: url("/fonts/LyonDisplay-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/LyonDisplay-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Display";
  src: url("/fonts/LyonDisplay-Regular-Web.woff2") format("woff2"),
    url("/fonts/LyonDisplay-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Display";
  src: url("/fonts/LyonDisplay-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/LyonDisplay-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Text";
  src: url("/fonts/LyonText-Black-Web.woff2") format("woff2"),
    url("/fonts/LyonText-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Text";
  src: url("/fonts/LyonText-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/LyonText-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Text";
  src: url("/fonts/LyonText-Bold-Web.woff2") format("woff2"),
    url("/fonts/LyonText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Text";
  src: url("/fonts/LyonText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/LyonText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Text";
  src: url("/fonts/LyonText-Regular-Web.woff2") format("woff2"),
    url("/fonts/LyonText-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Text";
  src: url("/fonts/LyonText-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/LyonText-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Text";
  src: url("/fonts/LyonText-RegularNo2-Web.woff2") format("woff2"),
    url("/fonts/LyonText-RegularNo2-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Text";
  src: url("/fonts/LyonText-RegularNo2Italic-Web.woff2") format("woff2"),
    url("/fonts/LyonText-RegularNo2Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Text";
  src: url("/fonts/LyonText-Semibold-Web.woff2") format("woff2"),
    url("/fonts/LyonText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Lyon Text";
  src: url("/fonts/LyonText-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/LyonText-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-Bold-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-Light-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-Medium-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-Regular-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-Semibold-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-Thin-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans Condensed";
  src: url("/fonts/MarrSansCondensed-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSansCondensed-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-Bold-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-Light-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-Medium-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-Regular-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-Semibold-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-Thin-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-UltraBlack-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-UltraBlack-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Marr Sans";
  src: url("/fonts/MarrSans-UltraBlackItalic-Web.woff2") format("woff2"),
    url("/fonts/MarrSans-UltraBlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Moulin";
  src: url("/fonts/Moulin-Thin-Web.woff2") format("woff2"),
    url("/fonts/Moulin-Thin-Web.woff") format("woff");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Moulin";
  src: url("/fonts/Moulin-Light-Web.woff2") format("woff2"),
    url("/fonts/Moulin-Light-Web.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Moulin";
  src: url("/fonts/Moulin-Regular-Web.woff2") format("woff2"),
    url("/fonts/Moulin-Regular-Web.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Moulin";
  src: url("/fonts/Moulin-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/Moulin-RegularItalic-Web.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Moulin";
  src: url("/fonts/Moulin-Medium-Web.woff2") format("woff2"),
    url("/fonts/Moulin-Medium-Web.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Moulin";
  src: url("/fonts/Moulin-Bold-Web.woff2") format("woff2"),
    url("/fonts/Moulin-Bold-Web.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Moulin";
  src: url("/fonts/Moulin-Black-Web.woff2") format("woff2"),
    url("/fonts/Moulin-Black-Web.woff") format("woff");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Moulin";
  src: url("/fonts/Moulin-Super-Web.woff2") format("woff2"),
    url("/fonts/Moulin-Super-Web.woff") format("woff");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Orientation";
  src: url("/fonts/Orientation-Bold-Web.woff2") format("woff2"),
    url("/fonts/Orientation-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Orientation";
  src: url("/fonts/Orientation-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Orientation-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Orientation";
  src: url("/fonts/Orientation-Light-Web.woff2") format("woff2"),
    url("/fonts/Orientation-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Orientation";
  src: url("/fonts/Orientation-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/Orientation-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Orientation";
  src: url("/fonts/Orientation-Regular-Web.woff2") format("woff2"),
    url("/fonts/Orientation-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Orientation";
  src: url("/fonts/Orientation-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/Orientation-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Original Sans";
  src: url("/fonts/OriginalSans-Four-Web.woff2") format("woff2"),
    url("/fonts/OriginalSans-Four-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Original Sans";
  src: url("/fonts/OriginalSans-FourItalic-Web.woff2") format("woff2"),
    url("/fonts/OriginalSans-FourItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Original Sans";
  src: url("/fonts/OriginalSans-Three-Web.woff2") format("woff2"),
    url("/fonts/OriginalSans-Three-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Original Sans";
  src: url("/fonts/OriginalSans-ThreeItalic-Web.woff2") format("woff2"),
    url("/fonts/OriginalSans-ThreeItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-Black-Web.woff2") format("woff2"),
    url("/fonts/Orleans-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/Orleans-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-Bold-Web.woff2") format("woff2"),
    url("/fonts/Orleans-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Orleans-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-Fat-Web.woff2") format("woff2"),
    url("/fonts/Orleans-Fat-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-FatItalic-Web.woff2") format("woff2"),
    url("/fonts/Orleans-FatItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-Italic-Web.woff2") format("woff2"),
    url("/fonts/Orleans-Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-Light-Web.woff2") format("woff2"),
    url("/fonts/Orleans-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/Orleans-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-Medium-Web.woff2") format("woff2"),
    url("/fonts/Orleans-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/Orleans-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-Roman-Web.woff2") format("woff2"),
    url("/fonts/Orleans-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-Thin-Web.woff2") format("woff2"),
    url("/fonts/Orleans-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Orleans";
  src: url("/fonts/Orleans-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/Orleans-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Portrait Text";
  src: url("/fonts/PortraitText-Bold-Web.woff2") format("woff2"),
    url("/fonts/PortraitText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait Text";
  src: url("/fonts/PortraitText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/PortraitText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait Text";
  src: url("/fonts/PortraitText-Heavy-Web.woff2") format("woff2"),
    url("/fonts/PortraitText-Heavy-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait Text";
  src: url("/fonts/PortraitText-HeavyItalic-Web.woff2") format("woff2"),
    url("/fonts/PortraitText-HeavyItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait Text";
  src: url("/fonts/PortraitText-Medium-Web.woff2") format("woff2"),
    url("/fonts/PortraitText-Medium-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait Text";
  src: url("/fonts/PortraitText-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/PortraitText-MediumItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait Text";
  src: url("/fonts/PortraitText-Regular-Web.woff2") format("woff2"),
    url("/fonts/PortraitText-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait Text";
  src: url("/fonts/PortraitText-Regular2-Web.woff2") format("woff2"),
    url("/fonts/PortraitText-Regular2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait Text";
  src: url("/fonts/PortraitText-Regular2Italic-Web.woff2") format("woff2"),
    url("/fonts/PortraitText-Regular2Italic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait Text";
  src: url("/fonts/PortraitText-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/PortraitText-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-Bold-Web.woff2") format("woff2"),
    url("/fonts/Portrait-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Portrait-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-Heavy-Web.woff2") format("woff2"),
    url("/fonts/Portrait-Heavy-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-HeavyItalic-Web.woff2") format("woff2"),
    url("/fonts/Portrait-HeavyItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-Light-Web.woff2") format("woff2"),
    url("/fonts/Portrait-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/Portrait-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-Medium-Web.woff2") format("woff2"),
    url("/fonts/Portrait-Medium-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/Portrait-MediumItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-Regular-Web.woff2") format("woff2"),
    url("/fonts/Portrait-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-Regular2-Web.woff2") format("woff2"),
    url("/fonts/Portrait-Regular2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-Regular2Italic-Web.woff2") format("woff2"),
    url("/fonts/Portrait-Regular2Italic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Portrait";
  src: url("/fonts/Portrait-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/Portrait-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-Black-Web.woff2") format("woff2"),
    url("/fonts/Produkt-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/Produkt-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-Bold-Web.woff2") format("woff2"),
    url("/fonts/Produkt-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Produkt-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-Extralight-Web.woff2") format("woff2"),
    url("/fonts/Produkt-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/Produkt-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-Light-Web.woff2") format("woff2"),
    url("/fonts/Produkt-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/Produkt-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-Medium-Web.woff2") format("woff2"),
    url("/fonts/Produkt-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/Produkt-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-Regular-Web.woff2") format("woff2"),
    url("/fonts/Produkt-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/Produkt-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-Semibold-Web.woff2") format("woff2"),
    url("/fonts/Produkt-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/Produkt-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-Super-Web.woff2") format("woff2"),
    url("/fonts/Produkt-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-SuperItalic-Web.woff2") format("woff2"),
    url("/fonts/Produkt-SuperItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-Thin-Web.woff2") format("woff2"),
    url("/fonts/Produkt-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Produkt";
  src: url("/fonts/Produkt-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/Produkt-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-Black-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-Bold-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-Italic-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-Light-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-Medium-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Headline";
  src: url("/fonts/PublicoHeadline-Roman-Web.woff2") format("woff2"),
    url("/fonts/PublicoHeadline-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text Mono";
  src: url("/fonts/PublicoTextMono-Bold-Web.woff2") format("woff2"),
    url("/fonts/PublicoTextMono-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text Mono";
  src: url("/fonts/PublicoTextMono-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/PublicoTextMono-BoldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text Mono";
  src: url("/fonts/PublicoTextMono-Italic-Web.woff2") format("woff2"),
    url("/fonts/PublicoTextMono-Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text Mono";
  src: url("/fonts/PublicoTextMono-ItalicNo2-Web.woff2") format("woff2"),
    url("/fonts/PublicoTextMono-ItalicNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text Mono";
  src: url("/fonts/PublicoTextMono-Roman-Web.woff2") format("woff2"),
    url("/fonts/PublicoTextMono-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text Mono";
  src: url("/fonts/PublicoTextMono-RomanNo2-Web.woff2") format("woff2"),
    url("/fonts/PublicoTextMono-RomanNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text Mono";
  src: url("/fonts/PublicoTextMono-Semibold-Web.woff2") format("woff2"),
    url("/fonts/PublicoTextMono-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text Mono";
  src: url("/fonts/PublicoTextMono-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/PublicoTextMono-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text";
  src: url("/fonts/PublicoText-Bold-Web.woff2") format("woff2"),
    url("/fonts/PublicoText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text";
  src: url("/fonts/PublicoText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/PublicoText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text";
  src: url("/fonts/PublicoText-Italic-Web.woff2") format("woff2"),
    url("/fonts/PublicoText-Italic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text";
  src: url("/fonts/PublicoText-Roman-Web.woff2") format("woff2"),
    url("/fonts/PublicoText-Roman-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text";
  src: url("/fonts/PublicoText-Semibold-Web.woff2") format("woff2"),
    url("/fonts/PublicoText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Publico Text";
  src: url("/fonts/PublicoText-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/PublicoText-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-Black-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-Bold-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-Heavy-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-Heavy-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-HeavyItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-HeavyItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-Light-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-Medium-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-Regular-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-Thin-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Condensed";
  src: url("/fonts/ReviewCondensed-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewCondensed-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-Black-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-Bold-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-Heavy-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-Heavy-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-HeavyItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-HeavyItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-Light-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-Medium-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-Regular-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-Thin-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Review Poster";
  src: url("/fonts/ReviewPoster-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/ReviewPoster-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-Black-Web.woff2") format("woff2"),
    url("/fonts/Review-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/Review-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-Bold-Web.woff2") format("woff2"),
    url("/fonts/Review-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Review-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-Heavy-Web.woff2") format("woff2"),
    url("/fonts/Review-Heavy-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-HeavyItalic-Web.woff2") format("woff2"),
    url("/fonts/Review-HeavyItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-Light-Web.woff2") format("woff2"),
    url("/fonts/Review-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/Review-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-Medium-Web.woff2") format("woff2"),
    url("/fonts/Review-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/Review-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-Regular-Web.woff2") format("woff2"),
    url("/fonts/Review-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/Review-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-Thin-Web.woff2") format("woff2"),
    url("/fonts/Review-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Review";
  src: url("/fonts/Review-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/Review-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Robinson";
  src: url("/fonts/Robinson-Black-Web.woff2") format("woff2"),
    url("/fonts/Robinson-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Robinson";
  src: url("/fonts/Robinson-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/Robinson-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Robinson";
  src: url("/fonts/Robinson-Bold-Web.woff2") format("woff2"),
    url("/fonts/Robinson-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Robinson";
  src: url("/fonts/Robinson-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Robinson-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Robinson";
  src: url("/fonts/Robinson-Light-Web.woff2") format("woff2"),
    url("/fonts/Robinson-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Robinson";
  src: url("/fonts/Robinson-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/Robinson-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Robinson";
  src: url("/fonts/Robinson-Medium-Web.woff2") format("woff2"),
    url("/fonts/Robinson-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Robinson";
  src: url("/fonts/Robinson-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/Robinson-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Robinson";
  src: url("/fonts/Robinson-Regular-Web.woff2") format("woff2"),
    url("/fonts/Robinson-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Robinson";
  src: url("/fonts/Robinson-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/Robinson-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Stencil";
  src: url("/fonts/SanomatSans-StencilBlack-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-StencilBlack-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Stencil";
  src: url("/fonts/SanomatSans-StencilBlackItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-StencilBlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-Bold-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-Book-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-Book-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-BookItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-BookItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-Light-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-Light-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-LightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-Medium-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-Regular-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-Semibold-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-XBold-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-XBold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans Text";
  src: url("/fonts/SanomatSansText-XBoldItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSansText-XBoldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-Black-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-Bold-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-Hairline-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-Hairline-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-HairlineItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-HairlineItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-Light-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-Light-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-LightItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-Medium-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-Medium-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-MediumItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-Regular-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-Regular-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-RegularItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-Thin-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-Thin-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-ThinItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-XBold-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-XBold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-XBoldItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-XBoldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-XLight-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-XLight-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/fonts/SanomatSans-XLightItalic-Web.woff2") format("woff2"),
    url("/fonts/SanomatSans-XLightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-Black-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-Bold-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-Light-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-Medium-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-Regular-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-Semibold-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Sanomat";
  src: url("/fonts/Sanomat-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/Sanomat-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Seance";
  src: url("/fonts/Seance-Ornate-Web.woff2") format("woff2"),
    url("/fonts/Seance-Ornate-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-Black-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-Bold-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-Light-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-Medium-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-Regular-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-Thin-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene A";
  src: url("/fonts/StyreneA-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneA-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-Black-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-Bold-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-Light-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-Medium-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-Regular-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-Thin-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Styrene B";
  src: url("/fonts/StyreneB-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/StyreneB-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Terza Editor";
  src: url("/fonts/TerzaEditor-Bold-Web.woff2") format("woff2"),
    url("/fonts/TerzaEditor-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Terza Editor";
  src: url("/fonts/TerzaEditor-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/TerzaEditor-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Terza Editor";
  src: url("/fonts/TerzaEditor-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/TerzaEditor-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Terza Editor";
  src: url("/fonts/TerzaEditor-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/TerzaEditor-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Terza Editor";
  src: url("/fonts/TerzaEditor-Light-Web.woff2") format("woff2"),
    url("/fonts/TerzaEditor-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Terza Editor";
  src: url("/fonts/TerzaEditor-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/TerzaEditor-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Terza Editor";
  src: url("/fonts/TerzaEditor-Medium-Web.woff2") format("woff2"),
    url("/fonts/TerzaEditor-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Terza Editor";
  src: url("/fonts/TerzaEditor-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/TerzaEditor-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Terza Editor";
  src: url("/fonts/TerzaEditor-Regular-Web.woff2") format("woff2"),
    url("/fonts/TerzaEditor-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Terza Editor";
  src: url("/fonts/TerzaEditor-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/TerzaEditor-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-Bold-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-Extrabold-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-Extrabold-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-ExtraboldItalic-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-ExtraboldItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-Light-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-Medium-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-Medium-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-MediumItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-Regular-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-RegularNo2-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-RegularNo2-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Terza Reader";
  src: url("/fonts/TerzaReader-RegularNo2Italic-Web.woff2") format("woff2"),
    url("/fonts/TerzaReader-RegularNo2Italic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Proxy";
  src: url("/fonts/Proxy-Medium-Web.woff2") format("woff2"),
    url("/fonts/Proxy-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}