@import "variables";

:root {
  --ui-label: "Graphik", sans-serif;
  --ui-value: "Graphik", sans-serif;
  --black: #000;
  --dark-gray: #161616;
  --mid-gray: #8b8a8a;
  --mid-light-gray: #c7c7c7;
  --light-gray: #f9f9f9;
  --white: #fff;
  --blue: #34aeff;
  --light-blue: #B6E2FF;
  --gutter: #{rem(50)};
  --site-max-width: #{rem(1440)};
  --site-padding: #{rem(20)};
  --container-width: MIN(calc((100vw) - var(--site-padding) * 2), #{rem(650)});
  --column: calc((var(--container-width) - var(--gutter) * 5) / 6);
  --site-gutter: calc((MIN(100vw, var(--site-max-width)) - var(--site-padding) * 2 - var(--container-width)) / 2);
  --shadow: 0 0 #{rem(4)} rgba(0, 0, 0, 0.1);

  --family-1: var(--ui-label);
  --family-2: var(--ui-label);
  --family-3: var(--ui-label);
  --ease-out: cubic-bezier(0.2, 0.8, 0.5, 1);

  --fg: var(--black);
  --fg-1: var(--dark-gray);
  --fg-2: var(--mid-gray);
  --bg: var(--white);
  --bg-1: var(--light-gray);

  --header-height: #{rem(48)};
  @include min-width($xs) {
    --header-height: #{rem(64)};
  }
  @include min-width($md) {
    --header-height: #{rem(80)};
  }
}

.isDark {
  --fg: var(--white);
  --fg-1: var(--light-gray);
  --fg-2: var(--mid-gray);
  --bg: var(--black);
  --bg-1: var(--dark-gray);
  ::selection {
    background: var(--blue);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  background: var(--light-blue);
}

button {
  appearance: none;
  border: 0;
  background: none;
  font: inherit;
  color: currentColor;
}

svg {
  * {
    fill: currentColor;
  }
}

figure,
p,
ul,
ol {
  margin: 0;
}

body {
  margin: 0;
  font-family: var(--ui-label);

  @media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx) {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
}

html {
  overflow-x: hidden;
  font-size: 16px;
  color: var(--fg);
  background-color: var(--bg);
  -webkit-text-size-adjust: 100%; /* iOS: Prevent font scaling in landscape */
}

.App {
  font-family: Frame Text;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  margin: 0;
}

.wrapper {
  max-width: var(--site-max-width);
  margin: auto;
}

a {
  color: inherit;
}

.theme-toggle {
  text-indent: -9999px;
  width: rem(15);
  height: rem(15);
  border-radius: 50%;
  background: var(--fg);
  border: none;
  flex: 0 0 auto;
  font-size: 0;
}

.random-button {
  padding: 0;
  padding-top: 0.27rem;
  height: 1.5rem;
  svg {
    transform: rotateZ(calc(var(--rotation) * 360deg));
    transition: transform 0.5s var(--ease-out);
    transform-origin: 41% 50%;
  }
}

.wordmark {
  color: var(--blue);
  margin: 0;
  width: rem(120);
  display: flex;
  align-items: center;
  @include min-width($md) {
    width: rem(132);
  }
}

.site-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 rem(20);
  height: var(--header-height);
  background: var(--bg);
  z-index: 1;

  .container {
    display: flex;
    align-items: center;
    gap: rem(20);

    a {
      font-family: var(--ui-label);
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: rem(19);
      text-align: left;
      color: var(--fg-1);
      text-decoration: none;
    }
  }
}

sup {
  vertical-align: unset;
  font-size: unset;
  font-feature-settings: "sups" 1;
  a {
    text-decoration: none;
  }
}

@import "index";
@import "article";
@import "utility";
