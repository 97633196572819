@use "sass:math";
@import "variables";
@mixin family($family) {
  --family-index: #{$family};
  --family: var(--family-#{$family});
  font-size: calc(var(--size-#{$family}, 1) * var(--font-size) * var(--desktop-multiplier, 1));
  line-height: var(--leading-#{$family}, var(--line-height));
  font-style: var(--style-#{$family}, var(--font-style));
  font-weight: var(--weight-#{$family}, var(--font-weight));
  letter-spacing: calc(var(--tracking-#{$family}, var(--letter-spacing)) * 1em);
}
.Article {
  position: relative;
  padding-inline: var(--site-padding);
  counter-reset: footnote;
  @include family(1);
  --font-weight: var(--weight-text);
  --font-size: #{rem(16)};
  @include min-width($md) {
    --font-size: #{rem(18)};
    padding-block-start: rem(20);
  }
  *, *::before, *::after {
    font-family: var(--family);
  }
  p,
  ul,
  ol,
  dl {
    @include family(1);
  }
  .preloader {
    @include family(1);
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  :where(&.paragraph-indents .body) {
    p {
      margin-block: 0;
      text-indent: rem(18);
    }
    blockquote p,
    .no-indent,
    .bio {
      text-indent: 0;
    }
  }
  :where(&.paragraph-spacing .body) {
    p {
      margin-block-start: rem(25);
    }
  }
  header {
    display: flex;
    flex-direction: column;
    gap: rem(15);
    @include min-width($md) {
      gap: rem(24);
    }
  }
  &.cover-position-after header {
    flex-direction: column-reverse;
  }
  .article-title,
  .byline {
    text-align: center;
  }
  .article-header-text,
  .body {
    width: 100%;
    max-width: var(--container-width);
    @include max-width($md) {
      margin-inline: auto;
    }
    @include min-width($lg) {
      margin-inline: auto;
    }
  }
  .FontSection {
    // box-shadow: 0 0 0 1px salmon;
    .transitioner {
      transition: opacity 250ms ease-in-out;
      &.loadingFonts {
        opacity: 0;
      }
    }
  }
  .article-title {
    @include family(2);
    --line-height: 1.2;
    margin-block: 0 rem(5);
    @include min-width($md) {
      --line-height: 1.09;
    }
  }
  @include headings {
    --font-weight: var(--weight-hed);
    margin-block: rem(35);
  }
  p {
    max-width: rem(650);
  }
  hr {
    border: none;
    height: 1.4em;
    display: block;
    margin-block: 0;
    width: 100%;
  }
  .cover-image {
    margin-inline: calc(-1 * var(--site-padding));
    width: calc(100% + 2 * var(--site-padding));
    @include min-width($md) {
      margin-inline: 0;
      max-width: var(--container-width);
    }
    @include min-width($lg) {
      margin-inline: auto;
    }
    video,
    img {
      height: 100%;
      width: 100%;
      max-width: 100%;
      height: auto;
      transition: 0.5s var(--ease-out);
    }
  }
  .byline {
    margin-block: rem(5) 0;
    color: var(--fg-2);
    --font-weight: var(--weight-byline);
  }
  .credit {
    color: var(--fg-2);
    @include family(1);
  }
  .body {
    margin-block-start: rem(24);
    margin-block-end: 8rem;
  }
  blockquote {
    --line-height: 1.2;
    margin-inline: 0;
    margin-block: rem(25);
    --font-weight: var(--weight-pullquote);
    p {
      @include family(2);
      position: relative;
      margin-inline: 0;
      margin-left: 0;
      padding-inline-start: rem(15);
      &::before {
        all: unset;
        content: "";
        position: absolute;
        left: 0;
        top: 0.2em;
        bottom: 0.2em;
        display: block;
        border-inline-start: 1px solid currentColor;
      }
    }
    @include min-width($lg) {
      p {
        margin-block: rem(25);
      }
    }
  }
  &.pulled-quotes {
    @include min-width($lg) {
      blockquote p {
        margin-left: calc((var(--column) + var(--gutter)) * -1);
      }
    }
  }
  .media {
    margin-block-start: rem(35);
    video {
      width: 100%;
      height: auto;
    }
    img {
      transition: 0.5s var(--ease-out);
    }
  }
  .posrel {
    position: relative;
  }
  p.bio {
    margin-top: 1.4em;
  }
  //
  // Food as Resistance at La Morada
  //
  &.local-news-article {
    .cover-image {
      @include max-width($md) {
        margin-inline: auto;
        width: unset;
        max-width: var(--container-width);
      }
    }
    p + p {
      margin-block-start: rem(20);
    }
    .article-title {
      text-align: left;
      --font-size: #{rem(28)};
      @include min-width($md) {
        --font-size: #{rem(48)};
      }
    }
    .author {
      --font-weight: 500;
    }
    .date {
    }
    .byline,
    p.bio {
      @include family(3);
    }
    blockquote {
      --font-size: #{rem(24)};
      @include min-width($md) {
        --font-size: #{rem(32)};
      }
    }
    .byline {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: rem(4);
      @include min-width($md) {
        flex-direction: row;
        gap: rem(16);
      }
    }
  }
  //
  // Kuih
  //
  &.listicle {
    .article-title {
      --font-size: #{rem(28)};
      margin-block-end: 0;
      @include min-width($md) {
        --font-size: #{rem(48)};
      }
    }
    .byline,
    p.bio {
      @include family(1);
    }
    .byline,
    .credit {
      --font-size: #{rem(19)};
      @include min-width($md) {
        --font-size: #{rem(22)};
      }
    }
    .credit {
      text-align: center;
    }
    p.bio {
      font-style: italic;
    }
    @include headings {
      margin-block-start: rem(20);
      margin-block-end: rem(15);
      @include family(2);
      --line-height: 1.2;
      --font-size: #{rem(28)};
      @include min-width($md) {
        --font-size: #{rem(40)};
      }
    }
    dl {
      margin-top: rem(12);
      dt {
        font-weight: var(--weight-hed);
        &::after {
          content: ":\00a0";
          font-weight: unset;
        }
        @include max-width($md) {
          float: left;
        }
      }
      dd {
        margin: 0;
        margin-bottom: rem(4);
      }
    }
    .media {
      margin-block-start: rem(35);
      margin-inline: auto;
      width: calc(100% - rem(30) * 2);
      height: 100%;
      @include min-width($md) {
        width: calc(100% - rem(117) * 2);
      }
    }
  }
  //
  // Becoming a Regular
  //
  &.personal-essay {
    .cover-image .credit {
      @include max-width($md) {
        max-width: var(--container-width);
        margin-inline: auto;
      }
    }
    .article-title {
      --font-size: #{rem(34)};
      @include min-width($md) {
        --font-size: #{rem(56)};
      }
    }
    .byline {
      @include family(1);
      --font-size: #{rem(19)};
      @include min-width($md) {
        --font-size: #{rem(28)};
      }
    }
    p.bio {
      text-align: center;
      font-style: italic;
    }
    @include headings {
      @include family(2);
    }
    blockquote {
      --font-size: #{rem(21)};
      @include min-width($md) {
        --font-size: #{rem(35)};
      }
    }
  }
  //
  // Harriet
  //
  &.short-fiction {
    .article-title {
      --font-size: #{rem(34)};
      @include min-width($md) {
        --font-size: #{rem(56)};
      }
    }
    .byline {
      @include family(1);
      --font-size: #{rem(19)};
      @include min-width($md) {
        --font-size: #{rem(28)};
      }
    }
    .credit {
      text-align: center;
      margin-block-start: rem(3);
      @include min-width($md) {
        margin-block-start: rem(7);
      }
    }
    blockquote {
      --font-size: #{rem(28)};
      @include min-width($md) {
        --font-size: #{rem(35)};
      }
    }
    .cover-image {
      @include min-width($lg) {
        max-width: MIN(calc(100vw - var(--gutter)), calc(var(--column) * 8 + var(--gutter) * 7));
      }
    }
    p.bio {
      text-indent: 0;
    }
  }
  //
  // This is Love
  //
  &.recipe {
    .article-title {
      --font-size: #{rem(28)};
      @include min-width($md) {
        --font-size: #{rem(48)};
      }
    }
    .byline {
      @include family(3);
      --font-size: #{rem(16)};
      @include min-width($md) {
        --font-size: #{rem(22)};
      }
    }
    .credit {
      margin-inline-start: 0;
      max-width: var(--container-width);
      @include max-width($md) {
        margin-inline: auto;
      }
      @include min-width($lg) {
        margin-inline: auto;
      }
    }
    h2 {
      --font-size: #{rem(21)};
      margin-block-end: rem(15);
      @include min-width($md) {
        --font-size: #{rem(24)};
        margin-block-end: rem(20);
      }
    }
    h3 {
      --font-size: #{rem(19)};
      margin-block-end: rem(12);
      @include min-width($md) {
        --font-size: #{rem(22)};
        margin-block: rem(15);
      }
    }
    hr {
      height: 0;
      @include min-width($md) {
        border-top: 1px solid var(--fg);
        height: auto;
        margin-block: rem(42);
      }
    }
    blockquote {
    }
    @include headings() {
      @include family(2);
    }
    .byline,
    .ingredients {
      @include family(3);
    }
    .two-col {
      display: grid;
      grid-gap: var(--gutter);
      @include min-width($sm) {
        grid-template-columns: 1fr 1fr;
      }
      @include headings() {
        margin-block-start: 0;
      }
      .col {
      }
    }
    ul {
      @include family(3);
      list-style: none;
      li {
        position: relative;
        b {
          position: absolute;
          right: calc(100% + 0.6rem);
        }
      }
    }
    ol.instructions {
      list-style: none;
      padding-left: 2rem;
      li {
        counter-increment: instructions;
        position: relative;
        margin-bottom: rem(25);
        @include min-width($md) {
          margin-bottom: rem(35);
        }
        &::before {
          content: counter(instructions) ". ";
          position: absolute;
          left: -1.9rem;
          @include family(3);
          font-weight: var(--weight-hed);
        }
      }
    }
  }
  //
  // Rosner
  //
  &.interview {
    .article-title {
      --font-size: #{rem(28)};
      @include min-width($md) {
        --font-size: #{rem(48)};
      }
    }
    .byline {
      @include family(1);
      --font-size: #{rem(19)};
      @include min-width($md) {
        --font-size: #{rem(28)};
      }
    }
    .credit {
      text-align: center;
      margin-block-start: rem(3);
      @include min-width($md) {
        margin-block-start: rem(7);
      }
    }
    h4 {
      @include family(3);
      --font-weight: var(--weight-question);
      @include min-width($md) {
        padding-right: calc(var(--column) + var(--gutter));
      }
      @include max-width($md) {
        margin-left: rem(25);
      }
    }
    .media {
      max-width: 100%;
      margin-inline: rem(25);
      @include min-width($md) {
        padding-inline: rem(45);
      }
    }
    blockquote {
      --font-size: #{rem(21)};
      @include min-width($md) {
        --font-size: #{rem(32)};
      }
    }
    .interview-body {
      b:first-child {
        @include family(3);
        --font-weight: var(--weight-question);
        line-height: 0.1;
      }
      & p {
        @include min-width($md) {
          margin-left: calc(var(--column) + var(--gutter));
        }
      }
      & blockquote p {
        @include min-width($md) {
          margin-left: 0;
        }
        @include min-width($lg) {
          margin-left: calc((var(--column) + var(--gutter)) * -1);
        }
      }
    }
  }
  .FontSection > .posrel aside {
    @include family(3);
    --font-size: #{rem(12)};
    color: var(--fg-2);
    font-weight: var(--weight-sidebar);
    @include max-width($lg) {
      margin-block-start: rem(18);
    }
    @include max-width($lg) {
      overflow-x: auto;
      width: 100vw;
      margin-left: calc(-1 * var(--site-gutter) - var(--site-padding));
      scroll-snap-type: x mandatory;
      scroll-padding: calc(var(--site-gutter) + var(--site-padding) + 0.5rem);
    }
    @include min-width($lg) {
      position: absolute;
      top: 0;
      right: 100%;
      padding-inline-end: var(--site-padding);
      width: var(--site-gutter);
      --font-size: #{rem(14)};
    }
    ol {
      margin-block: 0;
      padding-inline: 0;
      @include max-width($lg) {
        display: flex;
        padding-left: rem(20);
        display: flex;
        padding-inline: calc(var(--site-gutter) + var(--site-padding));
        width: min-content;
      }
      li {
        position: relative;
        margin-left: 1em;
        padding-left: 0.5em;
        scroll-snap-align: start;
        line-height: 1.4;
        padding-block-start: var(--header-height);
        margin-block-start: calc(-1 * var(--header-height));
        margin-block-end: rem(29);
        list-style: none;
        @include max-width($lg) {
          width: MIN(rem(280), 80vw);
          margin-block-end: rem(14);
          padding-right: 2rem;
          flex: 0 0 auto;
        }
        &:target {
          color: var(--fg-1);
        }
        counter-increment: footnote;
        &::before {
          position: absolute;
          left: -0.4rem;
          font-feature-settings: "sups" 1;
          content: counter(footnote) " ";
        }
      }
    }
  }
  footer {
    position: relative;
    padding-block: rem(40);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      background: var(--bg-1);
    }
    .container {
      position: relative;
      max-width: rem(650);
      @include max-width($md) {
        margin-inline: auto;
      }
      @include min-width($lg) {
        margin-inline: auto;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      font-family: var(--ui-label);
      font-size: rem(16);
      color: var(--fg-1);
    }
    .footer-articles {
      display: grid;
      gap: rem(25);
    }
    .footer-card {
      display: block;
      text-decoration: none;
      .title,
      .author {
        margin-block: rem(10);
        line-height: 1.4;
      }
      .title {
        @include family(1);
        --font-weight: 700;
        --line-height: 1.28;
        margin-block: 0 rem(2);
        text-align: left;
        --font-size: #{rem(16)};
      }
      .author {
        @include family(1);
        --font-weight: 400;
        --font-size: #{rem(16)};
        --line-height: 1.1875;
        margin: 0;
      }
    }
  }
}
