@use "sass:math";

$xs: 390px;
$sm: 640px;
$md: 880px;
$lg: 1160px;
$xl: 1280px;
$xxl: 1500px;

@mixin min-width($media) {
  @media only screen and (min-width: $media + 1px) {
    @content;
  }
}

@mixin max-width($media) {
  @media only screen and (max-width: $media) {
    @content;
  }
}

@function rem($px, $base: 16) {
  $remSize: $px / $base;
  @return #{$remSize}rem;
}

@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}
